import { PaginationPayload } from '../../../commons/types/pagination.type';
import {
  GetCouponPayload,
  GetFilteredCouponByDatePayload,
  GetTotalCouponResponsePayload,
  ICreateCouponPayload,
  ICreateCouponResponsePayload,
} from '../coupon_types';
import { CouponRepository } from '../repositories/coupon.repo';

export class CouponService {
  constructor(private readonly couponRepo: CouponRepository) {}

  async createCoupon(payload: ICreateCouponPayload): Promise<ICreateCouponResponsePayload> {
    const createCouponResponse = await this.couponRepo.createCoupon(payload);
    return createCouponResponse;
  }

  async getNewCoupons(arg?: PaginationPayload): Promise<GetCouponPayload> {
    const getNewCouponsResponse = await this.couponRepo.getNewCoupons(arg);
    return getNewCouponsResponse;
  }

  async getTotalCoupon(): Promise<GetTotalCouponResponsePayload> {
    const getTotalCouponResponse = await this.couponRepo.getTotalCoupon();
    return getTotalCouponResponse;
  }

  async getFilteredCoupon(payload: GetFilteredCouponByDatePayload): Promise<GetCouponPayload> {
    const getFilteredCouponResponse = await this.couponRepo.getFilteredCoupon(payload);
    return getFilteredCouponResponse;
  }
}
