import { PaginationPayload } from '../../../commons/types/pagination.type';
import {
  GetFilteredFleetOwnersPayload,
  GetTotalBlockedFleetOwnersPayload,
  IBlockFleetOwnerPayload,
  IBlockFleetOwnerResponsePayload,
  IConfirmFleetOwnerPayload,
  IConfirmFleetOwnerResponsePayload,
  IDeclineFleetOwnerPayload,
  IGetTotalActiveFleetOwnersPayload,
  IGetTotalFleetOwnersDetailsPayload,
  IGetTotalFleetOwnersDetailsResponsePayload,
  IGetTotalFleetOwnersPayload,
  IGetTotalFleetOwnersResponsePayload,
  IGetTotalFleetOwnersWithDatePayload,
  IPendingFleetOwnerResponsePayload,
} from '../fleet_owner_types';
import { FleetOwnerRepository } from '../repositories/fleet_owner.repo';

export class FleetOwnerService {
  constructor(private readonly fleetOwnerRepo: FleetOwnerRepository) {}

  async blockFleetOwner(payload: IBlockFleetOwnerPayload): Promise<IBlockFleetOwnerResponsePayload> {
    const blockFleetOwnerResponse = await this.fleetOwnerRepo.blockFleetOwner(payload);
    return blockFleetOwnerResponse;
  }

  async confirmFleetOwner(payload: IConfirmFleetOwnerPayload): Promise<IConfirmFleetOwnerResponsePayload> {
    const confirmFleetOwnerResponse = await this.fleetOwnerRepo.confirmFleetOwner(payload);
    return confirmFleetOwnerResponse;
  }

  async declineFleetOwner(payload: IDeclineFleetOwnerPayload): Promise<IConfirmFleetOwnerResponsePayload> {
    const declineFleetOwnerResponse = await this.fleetOwnerRepo.declineFleetOwner(payload);
    return declineFleetOwnerResponse;
  }

  async getActiveFleetOwners(arg?: PaginationPayload): Promise<IPendingFleetOwnerResponsePayload> {
    const getActiveFleetOwnersResponse = await this.fleetOwnerRepo.getActiveFleetOwners(arg);
    return getActiveFleetOwnersResponse;
  }

  async getBlockedFleetOwners(arg?: PaginationPayload): Promise<IPendingFleetOwnerResponsePayload> {
    const getBlockedFleetOwnersResponse = await this.fleetOwnerRepo.getBlockedFleetOwners(arg);
    return getBlockedFleetOwnersResponse;
  }

  async getFleetOwnerDetails(
    payload: IGetTotalFleetOwnersDetailsPayload
  ): Promise<IGetTotalFleetOwnersDetailsResponsePayload> {
    const getFleetOwnerDetailsResponse = await this.fleetOwnerRepo.getFleetOwnerDetails(payload);
    return getFleetOwnerDetailsResponse;
  }

  async getPendingFleetOwners(arg?: PaginationPayload): Promise<IPendingFleetOwnerResponsePayload> {
    const getBlockedFleetOwnersResponse = await this.fleetOwnerRepo.getPendingFleetOwners(arg);
    return getBlockedFleetOwnersResponse;
  }

  async getTotalNumberOfActiveFleetOwners(): Promise<IGetTotalActiveFleetOwnersPayload> {
    const getTotalNumberOfActiveFleetOwnersResponse = await this.fleetOwnerRepo.getTotalNumberOfActiveFleetOwners();
    return getTotalNumberOfActiveFleetOwnersResponse;
  }

  async getTotalFleetOwners(): Promise<IGetTotalFleetOwnersPayload> {
    const getTotalFleetOwnersResponse = await this.fleetOwnerRepo.getTotalFleetOwners();
    return getTotalFleetOwnersResponse;
  }

  async geTotalFleetOwnersWithinDateRange(
    payload: IGetTotalFleetOwnersWithDatePayload
  ): Promise<IGetTotalFleetOwnersResponsePayload> {
    const geTotalFleetOwnersWithinDateRangeResponsePayload =
      await this.fleetOwnerRepo.geTotalFleetOwnersWithinDateRange(payload);
    return geTotalFleetOwnersWithinDateRangeResponsePayload;
  }

  async getFilteredFleetOwners(payload: GetFilteredFleetOwnersPayload): Promise<IPendingFleetOwnerResponsePayload> {
    const getFilteredFleetOwnersResponse = await this.fleetOwnerRepo.getFilteredFleetOwners(payload);
    return getFilteredFleetOwnersResponse;
  }

  async getTotalNumberOfBlockedFleetOwners(): Promise<GetTotalBlockedFleetOwnersPayload> {
    const getTotalNumberOfBlockedFleetOwnersResponse = await this.fleetOwnerRepo.getTotalNumberOfBlockedFleetOwners();
    return getTotalNumberOfBlockedFleetOwnersResponse;
  }
}
