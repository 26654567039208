import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredStaffsPayload, IGetAllStaffState } from '../sub_admin_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { SubAdminRepository } from '../repositories/sub_admin.repo';
import { SubAdminService } from '../services/sub_admin.service';

const NAMESPACE = 'GET_ALL_STAFFS';

const initialState: IGetAllStaffState = {
  status: HttpStatus.IDLE,
  message: undefined,
  subAdmins: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const getAllStaffs = createAsyncThunk(`${NAMESPACE}/staffs`, async (arg?: PaginationPayload) => {
  const subAdminRepo = new SubAdminRepository();
  const subAdminService = new SubAdminService(subAdminRepo);
  const response = await subAdminService.getAllStaffs(arg);
  return response;
});

export const getFilteredStaff = createAsyncThunk(
  `${NAMESPACE}/staffs-filter`,
  async (payload: GetFilteredStaffsPayload) => {
    const subAdminRepo = new SubAdminRepository();
    const subAdminService = new SubAdminService(subAdminRepo);
    const response = await subAdminService.getFilteredStaff(payload);
    return response;
  }
);

const getAllStaffsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllStaffs.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getAllStaffs.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.subAdmins = payload.subAdmins;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getAllStaffs.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getFilteredStaff.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getFilteredStaff.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.subAdmins = payload.subAdmins;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getFilteredStaff.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getAllStaffsSlice.reducer;
