import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredVendorPayload, IGetPendingVendorsState } from '../vendor_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'LIST_OF_APPROVED_VENDORS';

const initialState: IGetPendingVendorsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  vendors: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const listOfApprovedVendors = createAsyncThunk(
  `${NAMESPACE}/approvedVendors`,
  async (arg?: PaginationPayload) => {
    const vendorRepo = new VendorRepository();
    const vendorService = new VendorService(vendorRepo);
    const response = await vendorService.getListOfApprovedVendors(arg);
    return response;
  }
);

export const getFilteredActiveVendors = createAsyncThunk(
  `${NAMESPACE}/filter-vendors`,
  async (payload: GetFilteredVendorPayload) => {
    const vendorRepo = new VendorRepository();
    const vendorService = new VendorService(vendorRepo);
    const response = await vendorService.getFilteredVendors(payload);
    return response;
  }
);

const listOfApprovedVendorsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listOfApprovedVendors.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(listOfApprovedVendors.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.vendors = payload.vendors;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(listOfApprovedVendors.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getFilteredActiveVendors.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getFilteredActiveVendors.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.vendors = payload.vendors;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getFilteredActiveVendors.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default listOfApprovedVendorsSlice.reducer;
