import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredAccountingPayload, IGetPendingWithdrawalState } from '../accounting_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { AccountingRepository } from '../repositories/accounting.repo';
import { AccountingService } from '../services/accounting.service';

const NAMESPACE = 'GET_PAYOUT_HISTORY';

const initialState: IGetPendingWithdrawalState = {
  status: HttpStatus.IDLE,
  message: undefined,
  withdrawals: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const getPayoutHistory = createAsyncThunk(`${NAMESPACE}/withdrawals`, async (arg?: PaginationPayload) => {
  const accountingRepo = new AccountingRepository();
  const accountingService = new AccountingService(accountingRepo);
  const response = await accountingService.getPayoutHistory(arg);
  return response;
});

export const getFilteredAccountingHistory = createAsyncThunk(
  `${NAMESPACE}/withdrawals-filter`,
  async (payload: GetFilteredAccountingPayload) => {
    const accountingRepo = new AccountingRepository();
    const accountingService = new AccountingService(accountingRepo);
    const response = await accountingService.getFilteredAccounting(payload);
    return response;
  }
);

const getPayoutHistorySlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPayoutHistory.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getPayoutHistory.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.withdrawals = payload.withdrawals;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getPayoutHistory.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getFilteredAccountingHistory.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getFilteredAccountingHistory.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.withdrawals = payload.withdrawals;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getFilteredAccountingHistory.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getPayoutHistorySlice.reducer;
