import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { Api } from '../../../utils';
import { ITotalProductsResponsePayload, ITotalProductsState } from './product_types';

const NAMESPACE = 'TOTAL_PRODUCTS';

const initialState: ITotalProductsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalProducts: 0 },
};

export const getTotalProducts = createAsyncThunk(`${NAMESPACE}/product`, async () => {
  const response = await Api.get<ITotalProductsResponsePayload, ITotalProductsResponsePayload>(
    '/v1/product/get-total-products'
  );
  return response;
});

const getTotalProductsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTotalProducts.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getTotalProducts.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getTotalProducts.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error.message ?? String(error);
    });
  },
});

export default getTotalProductsSlice.reducer;
