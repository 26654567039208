import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetTotalCouponState } from '../coupon_types';
import { CouponRepository } from '../repositories/coupon.repo';
import { CouponService } from '../services/coupon.service';

const NAMESPACE = 'GET_TOTAL_COUPON';

const initialState: GetTotalCouponState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalCoupons: 0 },
};

export const getTotalCoupon = createAsyncThunk(`${NAMESPACE}/coupon`, async () => {
  const couponRepo = new CouponRepository();
  const couponService = new CouponService(couponRepo);
  const response = await couponService.getTotalCoupon();
  return response;
});

const getTotalCouponSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTotalCoupon.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getTotalCoupon.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getTotalCoupon.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getTotalCouponSlice.reducer;
