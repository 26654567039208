import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api } from '../../utils';
import { HttpStatus } from '../../commons';

const NAMESPACE = 'WHO_AM_I';

export interface WhoAmIInitialState {
  status: HttpStatus;
  message?: string;
}

const initialState: WhoAmIInitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const checkWhoAmI = createAsyncThunk(`${NAMESPACE}/get`, async () => {
  const response = await Api.get(`/v1/admin/who-am-i`);
  return response;
});

export const whoAmISlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    unauth: (state) => {
      state.status = HttpStatus.ERROR;
    },
    reset: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkWhoAmI.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(checkWhoAmI.fulfilled, (state, action) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(checkWhoAmI.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { unauth, reset } = whoAmISlice.actions;

export default whoAmISlice.reducer;
