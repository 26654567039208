import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Spinner from './components/spinner/spinner';

const Login = lazy(() => import('./pages/authentication'));
const OTP = lazy(() => import('./pages/authentication/components/otp'));
const PrivatePolicy = lazy(() => import('./pages/privatePolicy/index'));

const MainLayout = lazy(() => import('./layout/dashboard'));
const Dashboard = lazy(() => import('./pages/analytics/dashboard'));
const Orders = lazy(() => import('./pages/orders/orders'));
const Vendor = lazy(() => import('./pages/vendor/vendor'));
const Rider = lazy(() => import('./pages/rider/rider'));
const FleetOwner = lazy(() => import('./pages/fleetOwner/fleet_owner'));
const Adverts = lazy(() => import('./pages/adverts/adverts'));
const Coupon = lazy(() => import('./pages/coupon/coupon'));
const AdminCommission = lazy(() => import('./pages/adminCommission'));
const Accounting = lazy(() => import('./pages/accounting/accounting'));
const GeoFence = lazy(() => import('./pages/geoFence/geofence'));
const SubAdmin = lazy(() => import('./pages/subAdmin/subAdmin'));
const Settings = lazy(() => import('./pages/settings/settings'));
const OrderDetails = lazy(() => import('./pages/orders/components/orderDetails/order_details'));
const VendorDetails = lazy(() => import('./pages/vendor/components/vendorDetails/vendor_details'));
const RiderDetails = lazy(() => import('./pages/rider/components/riderDetails/rider_details'));
const FleetOwnerDetails = lazy(() => import('./pages/fleetOwner/components/fleetOwnerDetails/fleet_owner_details'));
const AddNewCoupon = lazy(() => import('./pages/coupon/components/addNewCoupon/add_new_coupon'));
const EditGeoFence = lazy(() => import('./pages/geoFence/components/edit/edit'));
const AddNew = lazy(() => import('./pages/geoFence/components/addNew/add_new'));
const AddNewCommission = lazy(() => import('./pages/adminCommission/components/addNewCommission/add_new_commission'));
const EditNewCommission = lazy(() => import('./pages/adminCommission/components/editCommission/index'));
const PaymentPage = lazy(() => import('./pages/payment'));

const ErrorPage = lazy(() => import('./pages/error/index'));

function App() {
  return (
    <Suspense fallback={<Spinner message="Building page..." />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/verifyAccount" element={<OTP />} />
          <Route path="/private-policy" element={<PrivatePolicy />} />

          <Route path="/dashboard" element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="order" element={<Orders />} />
            <Route path="order/:id" element={<OrderDetails />} />
            <Route path="vendors" element={<Vendor />} />
            <Route path="vendors/:id" element={<VendorDetails />} />
            <Route path="riders" element={<Rider />} />
            <Route path="riders/:id" element={<RiderDetails />} />
            <Route path="fleet_owner" element={<FleetOwner />} />
            <Route path="fleet_owner/:id" element={<FleetOwnerDetails />} />
            <Route path="adverts" element={<Adverts />} />
            <Route path="coupon" element={<Coupon />} />
            <Route path="coupon/add_new" element={<AddNewCoupon />} />
            <Route path="admin-commission" element={<AdminCommission />} />
            <Route path="admin-commission/:add-new" element={<AddNewCommission />} />
            <Route path="admin-commission/:edit" element={<EditNewCommission />} />
            <Route path="accounting" element={<Accounting />} />
            <Route path="geo_fence" element={<GeoFence />} />
            <Route path="geo_fence/:edit" element={<EditGeoFence />} />
            <Route path="geo_fence/add_new" element={<AddNew />} />
            <Route path="subAdmin" element={<SubAdmin />} />
            <Route path="settings" element={<Settings />} />
            <Route path="payment" element={<PaymentPage />} />
          </Route>

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
