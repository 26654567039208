import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetTotalVendorsWithDatePayload, IGetTotalVendorsState } from '../vendor_types';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'GET_TOTAL_VENDORS';

const initialState: IGetTotalVendorsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalEmailVerifiedVendors: 0 },
  totalVendors: 0,
};

export const numberOfVendors = createAsyncThunk(`${NAMESPACE}/vendors`, async () => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.getNumberOfVendors();
  return response;
});

export const totalVendorsWithinDateRange = createAsyncThunk(
  `${NAMESPACE}/vendors-with-date`,
  async (payload: GetTotalVendorsWithDatePayload) => {
    const vendorRepo = new VendorRepository();
    const vendorService = new VendorService(vendorRepo);
    const response = await vendorService.getTotalVendorsWithinDateRange(payload);
    return response.totalVendors;
  }
);

export const TotalVendorSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(numberOfVendors.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(numberOfVendors.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(numberOfVendors.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(totalVendorsWithinDateRange.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalVendorsWithinDateRange.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.totalVendors = payload;
    });
    builder.addCase(totalVendorsWithinDateRange.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default TotalVendorSlice.reducer;
