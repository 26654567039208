import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredCouponByDatePayload, GetNewlyCreatedCouponState } from '../coupon_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { CouponRepository } from '../repositories/coupon.repo';
import { CouponService } from '../services/coupon.service';

const NAMESPACE = 'GET_NEWLY_CREATED_COUPONS';

const initialState: GetNewlyCreatedCouponState = {
  status: HttpStatus.IDLE,
  message: undefined,
  coupons: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const getNewCoupons = createAsyncThunk(`${NAMESPACE}/coupons`, async (arg?: PaginationPayload) => {
  const couponRepo = new CouponRepository();
  const couponService = new CouponService(couponRepo);
  const response = couponService.getNewCoupons(arg);
  return response;
});

export const getFilteredCoupon = createAsyncThunk(
  `${NAMESPACE}/coupon-filter`,
  async (payload: GetFilteredCouponByDatePayload) => {
    const couponRepo = new CouponRepository();
    const couponService = new CouponService(couponRepo);
    const response = couponService.getFilteredCoupon(payload);
    return response;
  }
);

const getNewCouponsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNewCoupons.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getNewCoupons.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.coupons = payload.coupons;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getNewCoupons.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getFilteredCoupon.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getFilteredCoupon.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.coupons = payload.coupons;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getFilteredCoupon.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getNewCouponsSlice.reducer;
