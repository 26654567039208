import { PaginationPayload, PaginationRequestPayload } from './types/pagination.type';

export const paginationFormatter = (arg?: PaginationPayload): PaginationRequestPayload => {
  const queryString: PaginationRequestPayload = {
    limit: 10,
  };
  if (arg === undefined) {
    return queryString;
  }
  if (arg.pageType === 'NEXT' && arg.paginationCursor?.hasNext) {
    queryString['after'] = arg.paginationCursor?.after;
  }
  if (arg.pageType === 'PREV' && arg.paginationCursor?.hasPrevious) {
    queryString['before'] = arg.paginationCursor?.before;
  }
  return queryString;
};
