import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FetchPaymentGatewaysInitialState } from '../payment.type';
import { HttpStatus } from '../../../commons';
import { PaymentRepository } from '../repository/payment.repo';
import { PaymentService } from '../service/payment.service';

const NAMESPACE = 'FETCH_PAYMENT_GATEWAYS';

const initialState: FetchPaymentGatewaysInitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
  gateways: [],
};

export const fetchPaymentGateways = createAsyncThunk(NAMESPACE, async () => {
  const paymentRepo = new PaymentRepository();
  const paymentService = new PaymentService(paymentRepo);
  const response = await paymentService.fetchPaymentGateways();
  return response;
});

const fetchPaymentGatewaysSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetPaymentStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentGateways.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(fetchPaymentGateways.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.gateways = payload.gateways;
    });
    builder.addCase(fetchPaymentGateways.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetPaymentStatus } = fetchPaymentGatewaysSlice.actions;

export default fetchPaymentGatewaysSlice.reducer;
