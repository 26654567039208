import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetTotalActiveFleetOwnersState } from '../fleet_owner_types';
import { FleetOwnerRepository } from '../repositories/fleet_owner.repo';
import { FleetOwnerService } from '../services/fleet_service.repo';

const NAMESPACE = 'TOTAL_ACTIVE_FLEET_OWNER';

const initialState: IGetTotalActiveFleetOwnersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalActiveFleetOwners: 0 },
};

export const totalNumberOfActiveFleetOwners = createAsyncThunk(`${NAMESPACE}/fleetOwners`, async () => {
  const fleetOwnerRepo = new FleetOwnerRepository();
  const fleetOwnerService = new FleetOwnerService(fleetOwnerRepo);
  const response = await fleetOwnerService.getTotalNumberOfActiveFleetOwners();
  return response;
});

const totalNumberOfActiveFleetOwnersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalNumberOfActiveFleetOwners.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalNumberOfActiveFleetOwners.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(totalNumberOfActiveFleetOwners.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalNumberOfActiveFleetOwnersSlice.reducer;
