import { PaginationPayload } from '../../../commons/types/pagination.type';
import {
  GetFilteredAccountingPayload,
  IDisbursePaymentPayload,
  IDisbursePaymentResponsePayload,
  IGetPendingWithdrawalResponsePayload,
} from '../accounting_types';
import { AccountingRepository } from '../repositories/accounting.repo';

export class AccountingService {
  constructor(private readonly accountingRepo: AccountingRepository) {}

  async getPayoutHistory(arg?: PaginationPayload): Promise<IGetPendingWithdrawalResponsePayload> {
    const getPayoutHistoryResponse = await this.accountingRepo.getPayoutHistory(arg);
    return getPayoutHistoryResponse;
  }

  async getPendingWithdrawal(arg?: PaginationPayload): Promise<IGetPendingWithdrawalResponsePayload> {
    const getPendingWithdrawalResponse = this.accountingRepo.getPendingWithdrawal(arg);
    return getPendingWithdrawalResponse;
  }

  async disbursePayment(payload: IDisbursePaymentPayload): Promise<IDisbursePaymentResponsePayload> {
    const disbursePaymentResponse = await this.accountingRepo.disbursePayment(payload);
    return disbursePaymentResponse;
  }

  async getFilteredAccounting(payload: GetFilteredAccountingPayload): Promise<IGetPendingWithdrawalResponsePayload> {
    const getFilteredAccountingResponse = await this.accountingRepo.getFilteredAccounting(payload);
    return getFilteredAccountingResponse;
  }
}
