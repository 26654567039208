import * as yup from 'yup';
import { addPaymentSchema } from './add_payment.schema';
import { HttpStatus } from '../../commons';

export enum PAGES {
  paymentForm = 'paymentForm',
  paymentGateway = 'paymentGateway',
}

export enum PAYMENTGATEWAYSTATUS {
  enabled = 'enabled',
  disabled = 'disabled',
}

export type AddPaymentSchema = yup.InferType<typeof addPaymentSchema>;

export type DefaultInitialState = {
  status: HttpStatus;
  message?: string;
};

export type AddPaymentResponsePayload = {
  gatewayId: string;
};

export interface AddPaymentInitialState extends DefaultInitialState {
  response?: AddPaymentResponsePayload;
}

export interface FetchPaymentGatewaysPayload extends AddPaymentResponsePayload {
  name: string;
  description: string;
  status: PAYMENTGATEWAYSTATUS;
  method: string;
  createdAt: string;
  updatedAt: string;
}

export type FetchPaymentGatewaysPayloadResponsePayload = {
  gateways: Array<FetchPaymentGatewaysPayload>;
};

export interface FetchPaymentGatewaysInitialState
  extends DefaultInitialState,
    FetchPaymentGatewaysPayloadResponsePayload {}
