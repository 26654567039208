import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetTotalRidersState, IGetTotalRidersWithDatePayload } from '../rider_types';
import { RiderRepository } from '../repositories/rider.repo';
import { RiderService } from '../services/rider.service';

const NAMESPACE = 'TOTAL_RIDERS';

const initialState: IGetTotalRidersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalRiders: 0 },
  totalRidersWithinDateRange: 0,
};

export const totalRiders = createAsyncThunk(`${NAMESPACE}/totalRiders`, async () => {
  const riderRepo = new RiderRepository();
  const riderService = new RiderService(riderRepo);
  const response = await riderService.getTotalRiders();
  return response;
});

export const totalRidersWithinDateRange = createAsyncThunk(
  `${NAMESPACE}/riders`,
  async (payload: IGetTotalRidersWithDatePayload) => {
    const riderRepo = new RiderRepository();
    const riderService = new RiderService(riderRepo);
    const response = await riderService.geTotalRidersWithinDateRange(payload);
    return response.totalRiders;
  }
);

const totalRidersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalRiders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalRiders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(totalRiders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(totalRidersWithinDateRange.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalRidersWithinDateRange.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.totalRidersWithinDateRange = payload;
    });
    builder.addCase(totalRidersWithinDateRange.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalRidersSlice.reducer;
