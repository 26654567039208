import { PaginationPayload } from '../../../commons/types/pagination.type';
import {
  IGetOrderDetailsPayload,
  IGetOrderDetailsResponsePayload,
  IGetTotalNumberOfPendingOrdersResponsePayload,
  IGetTotalPlacedOrdersResponsePayloadArray,
  ITotalOrderPerDayPayload,
  ITotalOrderPerDayResponsePayload,
  GetFilteredOrderPayload,
  IGetTotalActiveOrdersResponseArray,
  IGetCompletedOrdersResponsePayload,
} from '../orders_types';
import { OrderRepository } from '../repositories/order.repo';

export class OrderService {
  constructor(private readonly orderRepo: OrderRepository) {}

  async getCompletedOrders(arg?: PaginationPayload): Promise<IGetCompletedOrdersResponsePayload> {
    const getCompletedOrdersResponse = await this.orderRepo.getCompletedOrders(arg);
    return getCompletedOrdersResponse;
  }

  async getTotalActiveOrders(arg?: PaginationPayload): Promise<IGetTotalActiveOrdersResponseArray> {
    const getTotalActiveOrdersResponse = await this.orderRepo.getTotalActiveOrders(arg);
    return getTotalActiveOrdersResponse;
  }

  async getOrderDetails(payload: IGetOrderDetailsPayload): Promise<IGetOrderDetailsResponsePayload> {
    const getOrderDetailsResponse = await this.orderRepo.getOrderDetails(payload);
    return getOrderDetailsResponse;
  }

  async totalOrdersPerDay(payload: ITotalOrderPerDayPayload): Promise<ITotalOrderPerDayResponsePayload> {
    const totalOrdersPerDayResponse = await this.orderRepo.totalOrdersPerDay(payload);
    return totalOrdersPerDayResponse;
  }

  async getTotalOrders(): Promise<IGetTotalNumberOfPendingOrdersResponsePayload> {
    const getTotalOrdersResponse = await this.orderRepo.getTotalOrders();
    return getTotalOrdersResponse;
  }

  async getTotalPlacedOrders(arg?: PaginationPayload): Promise<IGetTotalPlacedOrdersResponsePayloadArray> {
    const getTotalPlacedOrdersResponse = await this.orderRepo.getTotalPlacedOrders(arg);
    return getTotalPlacedOrdersResponse;
  }

  async numberOfActiveOrders(): Promise<IGetTotalNumberOfPendingOrdersResponsePayload> {
    const numberOfActiveOrdersResponse = await this.orderRepo.numberOfActiveOrders();
    return numberOfActiveOrdersResponse;
  }

  async numberOfCancelledOrders(): Promise<IGetTotalNumberOfPendingOrdersResponsePayload> {
    const numberOfCancelledOrdersResponse = await this.orderRepo.numberOfCancelledOrders();
    return numberOfCancelledOrdersResponse;
  }

  async numberOfDeliveredOrders(): Promise<IGetTotalNumberOfPendingOrdersResponsePayload> {
    const numberOfDeliveredOrdersResponse = await this.orderRepo.numberOfDeliveredOrders();
    return numberOfDeliveredOrdersResponse;
  }

  async numberOfPendingOrders(): Promise<IGetTotalNumberOfPendingOrdersResponsePayload> {
    const numberOfPendingOrdersResponse = await this.orderRepo.numberOfPendingOrders();
    return numberOfPendingOrdersResponse;
  }

  async getFilteredOrders(payload: GetFilteredOrderPayload): Promise<IGetTotalActiveOrdersResponseArray> {
    const getFilteredOrdersResponse = await this.orderRepo.getFilteredOrders(payload);
    return getFilteredOrdersResponse;
  }
}
