import { Api } from '../../../utils';
import {
  GetUserEmailPayload,
  GetUserEmailResponsePayload,
  IVerifyOTPPayload,
  IVerifyOTPResponsePayload,
} from '../auth_types';

export class AuthenticationRepository {
  async getUserEmail(payload: GetUserEmailPayload): Promise<GetUserEmailResponsePayload> {
    const response = await Api.post<GetUserEmailResponsePayload, GetUserEmailResponsePayload>(
      '/v1/admin/login',
      payload
    );
    const { email } = payload;
    window.sessionStorage.setItem('userEmail', email);
    return response;
  }

  async verifyUserOTP(otp: string): Promise<IVerifyOTPResponsePayload> {
    const email = window.sessionStorage.getItem('userEmail') as string;
    const payload: IVerifyOTPPayload = { email, otp };
    const response = await Api.post<IVerifyOTPResponsePayload, IVerifyOTPResponsePayload>(
      '/v1/admin/verify-otp',
      payload
    );
    return response;
  }
}
