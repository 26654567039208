import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetGeoFenceDetailsState, IGetGeoFenceDetailsPayload } from '../geo_fence_types';
import { GeoFenceRepository } from '../repositories/geo_fence.repo';
import { GeoFenceService } from '../services/geo_fence.service';

const NAMESPACE = 'GET_GEO_FENCE_DETAILS';

const initialState: IGetGeoFenceDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getGeoFenceDetails = createAsyncThunk(
  `${NAMESPACE}/geoFence`,
  async (payload: IGetGeoFenceDetailsPayload) => {
    const geoFenceRepo = new GeoFenceRepository();
    const geoFenceService = new GeoFenceService(geoFenceRepo);
    const response = await geoFenceService.getGeoFenceDetails(payload);
    return response;
  }
);

export const getGeoFenceDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetGeoFenceStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGeoFenceDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getGeoFenceDetails.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getGeoFenceDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetGeoFenceStatus } = getGeoFenceDetailsSlice.actions;

export default getGeoFenceDetailsSlice.reducer;
