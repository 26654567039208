import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetNotificationState, IGetNotificationPayload } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'GET_NOTIFICATION';

const initialState: IGetNotificationState = {
  status: HttpStatus.IDLE,
  message: undefined,
  pushNotification: false,
  emailNotification: false,
  pickUpStatus: false,
  dropOffStatus: false,
  smsNotification: false,
  updateNotificationStatus: HttpStatus.IDLE,
};

export const getNotification = createAsyncThunk(`${NAMESPACE}/getNotification`, async () => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = settingService.getNotification();
  return response;
});

export const updateNotification = createAsyncThunk(
  `${NAMESPACE}/updateNotification`,
  async (payload: IGetNotificationPayload) => {
    const settingRepo = new SettingRepository();
    const settingService = new SettingService(settingRepo);
    const response = settingService.updateNotification(payload);
    return response;
  }
);

const getNotificationSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    togglePushNotification: (state, action) => {
      state.pushNotification = action.payload;
    },
    toggleSmsNotification: (state, action) => {
      state.smsNotification = action.payload;
    },
    toggleEmailNotification: (state, action) => {
      state.emailNotification = action.payload;
    },
    togglePickUpStatus: (state, action) => {
      state.pickUpStatus = action.payload;
    },
    toggleDropOffStatus: (state, action) => {
      state.dropOffStatus = action.payload;
    },
    resetStatus: (state) => {
      state.updateNotificationStatus = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotification.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getNotification.fulfilled, (state, payload) => {
      state.status = HttpStatus.DONE;
      state.dropOffStatus = payload.payload.dropOffStatus;
      state.pushNotification = payload.payload.pushNotification;
      state.emailNotification = payload.payload.emailNotification;
      state.pickUpStatus = payload.payload.pickUpStatus;
      state.smsNotification = payload.payload.smsNotification;
    });
    builder.addCase(getNotification.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(updateNotification.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(updateNotification.fulfilled, (state) => {
      state.updateNotificationStatus = HttpStatus.DONE;
    });
    builder.addCase(updateNotification.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const {
  togglePushNotification,
  toggleSmsNotification,
  toggleEmailNotification,
  togglePickUpStatus,
  toggleDropOffStatus,
  resetStatus,
} = getNotificationSlice.actions;

export default getNotificationSlice.reducer;
