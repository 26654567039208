import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredVendorPayload, IGetPendingVendorsState } from '../vendor_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'LIST_OF_BLOCKED_VENDORS';

const initialState: IGetPendingVendorsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  vendors: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const listOfBlockedVendors = createAsyncThunk(`${NAMESPACE}/blockedVendors`, async (arg?: PaginationPayload) => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.getListOfBlockedVendors(arg);
  return response;
});

export const getFilteredBlockedVendors = createAsyncThunk(
  `${NAMESPACE}/filter-vendors`,
  async (payload: GetFilteredVendorPayload) => {
    const vendorRepo = new VendorRepository();
    const vendorService = new VendorService(vendorRepo);
    const response = await vendorService.getFilteredVendors(payload);
    return response;
  }
);

const listOfBlockedVendorsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listOfBlockedVendors.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(listOfBlockedVendors.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.vendors = payload.vendors;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(listOfBlockedVendors.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getFilteredBlockedVendors.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getFilteredBlockedVendors.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.vendors = payload.vendors;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getFilteredBlockedVendors.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default listOfBlockedVendorsSlice.reducer;
