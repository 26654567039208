import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../utils';

const NAMESPACE = 'CHANGE_PAGE_RIDER';

const initialState = {
  page: PAGES.registrationRequest,
};

export const riderSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePage } = riderSlice.actions;

export default riderSlice.reducer;
