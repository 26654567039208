import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { Api } from '../../../utils';
import { IGetTotalUsersState, IGetTotalUsersResponsePayload, IGetTotalUsersPayload } from './user_types';

const NAMESPACE = 'TOTAL_USERS';

const initialState: IGetTotalUsersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  totalUsers: 0,
  totalUsersWithinDateRange: 0,
};

export const totalUsers = createAsyncThunk(`${NAMESPACE}/users`, async () => {
  const response = await Api.get<IGetTotalUsersResponsePayload, IGetTotalUsersResponsePayload>('/v1/user');
  return response;
});

export const totalUsersWithinDateRange = createAsyncThunk(
  `${NAMESPACE}/totalUsersWithMonthRange`,
  async (payload: IGetTotalUsersPayload) => {
    const response = await Api.get<IGetTotalUsersResponsePayload, IGetTotalUsersResponsePayload>(
      'v1/user/total-users-with-date',
      { params: payload }
    );
    return response;
  }
);

const totalUsersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalUsers.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalUsers.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.totalUsers = payload.totalUsers;
    });
    builder.addCase(totalUsers.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error.message ?? String(error);
    });
    builder.addCase(totalUsersWithinDateRange.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalUsersWithinDateRange.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.totalUsersWithinDateRange = payload.totalUsers;
    });
    builder.addCase(totalUsersWithinDateRange.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error.message ?? String(error);
    });
  },
});

export default totalUsersSlice.reducer;
