import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetActiveVendorsState } from '../vendor_types';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'GET_ACTIVE_VENDORS';

const initialState: IGetActiveVendorsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalApprovedVendors: 0 },
};

export const numberOfActiveVendors = createAsyncThunk(`${NAMESPACE}/vendors`, async () => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.getNumberOfActiveVendors();
  return response;
});

export const ActiveVendorSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(numberOfActiveVendors.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(numberOfActiveVendors.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(numberOfActiveVendors.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default ActiveVendorSlice.reducer;
