import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ICreateGeoFencePayload, ICreateGeoFenceState } from '../geo_fence_types';
import { GeoFenceRepository } from '../repositories/geo_fence.repo';
import { GeoFenceService } from '../services/geo_fence.service';

const NAMESPACE = 'CREATE_GEO_FENCE';

const initialState: ICreateGeoFenceState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const createGeoFence = createAsyncThunk(`${NAMESPACE}/geoFence`, async (payload: ICreateGeoFencePayload) => {
  const geoFenceRepo = new GeoFenceRepository();
  const geoFenceService = new GeoFenceService(geoFenceRepo);
  const response = await geoFenceService.createGeoFence(payload);
  return response;
});

export const createGeoFenceSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createGeoFence.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(createGeoFence.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(createGeoFence.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = createGeoFenceSlice.actions;

export default createGeoFenceSlice.reducer;
