import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IDeclineFleetOwnerPayload, IConfirmFleetOwnerState } from '../fleet_owner_types';
import { FleetOwnerService } from '../services/fleet_service.repo';
import { FleetOwnerRepository } from '../repositories/fleet_owner.repo';

const NAMESPACE = 'DECLINE_FLEET_OWNER';

const initialState: IConfirmFleetOwnerState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const declineFleetOwner = createAsyncThunk(
  `${NAMESPACE}/fleetOwner`,
  async (payload: IDeclineFleetOwnerPayload) => {
    const fleetOwnerRepo = new FleetOwnerRepository();
    const fleetOwnerService = new FleetOwnerService(fleetOwnerRepo);
    const response = await fleetOwnerService.declineFleetOwner(payload);
    return response;
  }
);

export const declineFleetOwnerSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetDeclineStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(declineFleetOwner.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(declineFleetOwner.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(declineFleetOwner.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetDeclineStatus } = declineFleetOwnerSlice.actions;

export default declineFleetOwnerSlice.reducer;
