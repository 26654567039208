import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetTotalFleetOwnersDetailsPayload, IGetTotalFleetOwnersDetailsState } from '../fleet_owner_types';
import { FleetOwnerRepository } from '../repositories/fleet_owner.repo';
import { FleetOwnerService } from '../services/fleet_service.repo';

const NAMESPACE = 'GET_FLEET_OWNER_DETAILS';

const initialState: IGetTotalFleetOwnersDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getFleetOwnerDetails = createAsyncThunk(
  `${NAMESPACE}/fleetOwner`,
  async (payload: IGetTotalFleetOwnersDetailsPayload) => {
    const fleetOwnerRepo = new FleetOwnerRepository();
    const fleetOwnerService = new FleetOwnerService(fleetOwnerRepo);
    const response = await fleetOwnerService.getFleetOwnerDetails(payload);
    return response;
  }
);

export const getFleetOwnerDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetFleetOwnerStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFleetOwnerDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getFleetOwnerDetails.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getFleetOwnerDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetFleetOwnerStatus } = getFleetOwnerDetailsSlice.actions;

export default getFleetOwnerDetailsSlice.reducer;
