import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IAddStaffPayload, IAddStaffState } from '../sub_admin_types';
import { SubAdminRepository } from '../repositories/sub_admin.repo';
import { SubAdminService } from '../services/sub_admin.service';

const NAMESPACE = 'ONBOARD_STAFF';

const initialState: IAddStaffState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const onBoardStaff = createAsyncThunk(`${NAMESPACE}/onBoardStaff`, async (payload: IAddStaffPayload) => {
  const subAdminRepo = new SubAdminRepository();
  const subAdminService = new SubAdminService(subAdminRepo);
  const response = await subAdminService.onBoardStaff(payload);
  return response;
});

const onBoardStaffSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onBoardStaff.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(onBoardStaff.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(onBoardStaff.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default onBoardStaffSlice.reducer;
