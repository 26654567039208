import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetAdminDetailsState } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'USER_DETAILS';

const initialState: IGetAdminDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: {
    email: 'example@gmail.com',
    type: '',
    level: 0,
    firstName: 'Dear',
    lastName: 'User',
    phoneNumber: '',
    teamId: '',
  },
};

export const userDetails = createAsyncThunk(`${NAMESPACE}/userDetails`, async () => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = settingService.getUserDetails();
  return response;
});

const userDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(userDetails.fulfilled, (state, action) => {
      state.status = HttpStatus.DONE;
      state.response = action.payload;
    });
    builder.addCase(userDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default userDetailsSlice.reducer;
