import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../utils';

const NAMESPACE = 'FLEET_OWNER_PAGE';

const initialState = {
  page: PAGES.pending,
};

export const fleetOwnerSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePage } = fleetOwnerSlice.actions;

export default fleetOwnerSlice.reducer;
