import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetRidersByLocationState, IGetRidersByLocationPayload } from '../rider_types';
import { RiderRepository } from '../repositories/rider.repo';
import { RiderService } from '../services/rider.service';

const NAMESPACE = 'GET_RIDER_BY_LOCATION';

const initialState: IGetRidersByLocationState = {
  status: HttpStatus.IDLE,
  message: undefined,
  riders: [],
};

export const getRiderByLocation = createAsyncThunk(
  `${NAMESPACE}/rider`,
  async (payload: IGetRidersByLocationPayload) => {
    const riderRepo = new RiderRepository();
    const riderService = new RiderService(riderRepo);
    const response = await riderService.getRiderByLocation(payload);
    return response.riders;
  }
);

export const getRiderByLocationSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetRiderStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRiderByLocation.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getRiderByLocation.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.riders = payload;
    });
    builder.addCase(getRiderByLocation.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetRiderStatus } = getRiderByLocationSlice.actions;

export default getRiderByLocationSlice.reducer;
