import { PaginationPayload } from '../../../commons/types/pagination.type';
import {
  AdminCommissionPayload,
  AdminCommissionResponseObject,
  AdminCommissionResponsePayload,
  CreateAdminCommissionResponsePayload,
  EditAdminCommissionFormPayload,
  EditAdminCommissionResponsePayload,
  GetAdminCommissionDetailsPayload,
  GetRiderAdminCommissionResponsePayload,
  GetVendorAdminCommissionResponsePayload,
} from '../admin_commission_types';
import { AdminCommissionRepository } from '../repositories/admin_commission.repo';
import { AddEntityCommission } from '../schema/add_entity_commission.schema';

export class AdminCommissionService {
  constructor(private readonly adminCommissionRepo: AdminCommissionRepository) {}

  async createAdminCommission(payload: AdminCommissionPayload): Promise<CreateAdminCommissionResponsePayload> {
    const createAdminCommissionResponse = await this.adminCommissionRepo.createAdminCommission(payload);
    return createAdminCommissionResponse;
  }

  async editAdminCommission(payload: EditAdminCommissionFormPayload): Promise<EditAdminCommissionResponsePayload> {
    const editAdminCommissionResponse = await this.adminCommissionRepo.editAdminCommission(payload);
    return editAdminCommissionResponse;
  }

  async getAdminCommissionDetails(payload: GetAdminCommissionDetailsPayload): Promise<AdminCommissionResponseObject> {
    const getAdminCommissionDetailsResponse = await this.adminCommissionRepo.getAdminCommissionDetails(payload);
    return getAdminCommissionDetailsResponse;
  }

  async getAdminCommission(arg?: PaginationPayload): Promise<AdminCommissionResponsePayload> {
    const getAdminCommissionResponse = await this.adminCommissionRepo.getAdminCommission(arg);
    return getAdminCommissionResponse;
  }

  async getRiderAdminCommission(): Promise<GetRiderAdminCommissionResponsePayload> {
    const getRiderAdminCommissionResponse = await this.adminCommissionRepo.getRiderAdminCommission();
    return getRiderAdminCommissionResponse;
  }

  async getVendorAdminCommission(): Promise<GetVendorAdminCommissionResponsePayload> {
    const getVendorAdminCommissionResponse = await this.adminCommissionRepo.getVendorAdminCommission();
    return getVendorAdminCommissionResponse;
  }

  async addPrivateCommission(payload: AddEntityCommission): Promise<CreateAdminCommissionResponsePayload> {
    const response = this.adminCommissionRepo.addPrivateCommission(payload);
    return response;
  }
}
