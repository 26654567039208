import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IDeclineVendorState, IBlockVendorPayload } from '../vendor_types';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'BLOCK_VENDORS';

const initialState: IDeclineVendorState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const blockVendor = createAsyncThunk(`${NAMESPACE}/blockVendor`, async (payload: IBlockVendorPayload) => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.blockVendor(payload);
  return response;
});

const blockVendorSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetBlockVendorStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(blockVendor.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(blockVendor.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(blockVendor.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetBlockVendorStatus } = blockVendorSlice.actions;

export default blockVendorSlice.reducer;
