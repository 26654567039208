import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetAdminPaymentSettingState } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'GET_ADMIN_PAYMENT_SETTING';

const initialState: IGetAdminPaymentSettingState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: {
    paymentGateway: {
      paystack: false,
      flutterwave: false,
    },
    payoutOptions: {
      offPlatform: false,
      flutterwave: false,
      paystack: false,
    },
    createdAt: '',
    updatedAt: '',
    version: 0,
    adminId: '',
    paymentSettingId: '',
  },
};

export const getAdminPaymentSetting = createAsyncThunk(`${NAMESPACE}/payment`, async () => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = settingService.getAdminPaymentSetting();
  return response;
});

const getAdminPaymentSettingSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminPaymentSetting.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getAdminPaymentSetting.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getAdminPaymentSetting.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getAdminPaymentSettingSlice.reducer;
