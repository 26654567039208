import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetTotalBlockedVendorsState } from '../vendor_types';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'GET_BLOCKED_VENDORS';

const initialState: IGetTotalBlockedVendorsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { total: 0 },
};

export const totalNumberOfBlockedVendors = createAsyncThunk(`${NAMESPACE}/vendors`, async () => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.getTotalNumberOfBlockedVendors();
  return response;
});

export const totalNumberOfBlockedVendorsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalNumberOfBlockedVendors.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalNumberOfBlockedVendors.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(totalNumberOfBlockedVendors.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalNumberOfBlockedVendorsSlice.reducer;
