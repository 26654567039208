import { GetUserEmailPayload, GetUserEmailResponsePayload, IVerifyOTPResponsePayload } from '../auth_types';
import { AuthenticationRepository } from '../repositories/authentication.repo';

export class AuthenticationService {
  constructor(private readonly authenticationRepo: AuthenticationRepository) {}

  async getUserEmail(payload: GetUserEmailPayload): Promise<GetUserEmailResponsePayload> {
    const getUserEmailResponse = await this.authenticationRepo.getUserEmail(payload);
    return getUserEmailResponse;
  }

  async verifyUserOTP(payload: string): Promise<IVerifyOTPResponsePayload> {
    const verifyUserOTPResponse = await this.authenticationRepo.verifyUserOTP(payload);
    return verifyUserOTPResponse;
  }
}
