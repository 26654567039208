import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IDeclineVendorState, IApproveVendorPayload } from '../vendor_types';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'CONFIRM_VENDORS';

const initialState: IDeclineVendorState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const confirmVendor = createAsyncThunk(`${NAMESPACE}/confirmVendor`, async (payload: IApproveVendorPayload) => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.confirmVendor(payload);
  return response;
});

const confirmVendorSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetConfirmVendorStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(confirmVendor.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(confirmVendor.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(confirmVendor.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetConfirmVendorStatus } = confirmVendorSlice.actions;

export default confirmVendorSlice.reducer;
