import { Box, Text, VStack } from '@chakra-ui/react';
import { SyncLoader } from 'react-spinners';

interface SpinnerProps {
  message: string;
}

export const Spinner = (props: SpinnerProps) => {
  const { message } = props;
  return (
    <Box
      display="flex"
      flexDir="column"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      bgColor="#fff"
      color="#111"
    >
      <VStack spacing={4}>
        <SyncLoader size={20} color={'#EE204A'} />
        <Text fontSize={{ lg: '1.25rem' }} fontWeight="500">
          {message}
        </Text>
      </VStack>
    </Box>
  );
};

export default Spinner;
