import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  IGetOrderDetailsPayload,
  IGetOrderDetailsResponsePayload,
  IGetTotalNumberOfPendingOrdersResponsePayload,
  IGetTotalPlacedOrdersResponsePayloadArray,
  ITotalOrderPerDayPayload,
  ITotalOrderPerDayResponsePayload,
  IGetTotalActiveOrdersResponseArray,
  GetFilteredOrderPayload,
  IGetCompletedOrdersResponsePayload,
} from '../orders_types';

export class OrderRepository {
  async getCompletedOrders(arg?: PaginationPayload): Promise<IGetCompletedOrdersResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetCompletedOrdersResponsePayload, IGetCompletedOrdersResponsePayload>(
      '/v1/orders/completed-orders',
      {
        params,
      }
    );
    return response;
  }

  async getTotalActiveOrders(arg?: PaginationPayload): Promise<IGetTotalActiveOrdersResponseArray> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetTotalActiveOrdersResponseArray, IGetTotalActiveOrdersResponseArray>(
      '/v1/orders/fetch-all-ongoing',
      {
        params,
      }
    );
    return response;
  }

  async getOrderDetails(payload: IGetOrderDetailsPayload): Promise<IGetOrderDetailsResponsePayload> {
    const response = await Api.get<IGetOrderDetailsResponsePayload, IGetOrderDetailsResponsePayload>(
      'v1/order/view-details',
      { params: payload }
    );
    return response;
  }

  async totalOrdersPerDay(payload: ITotalOrderPerDayPayload): Promise<ITotalOrderPerDayResponsePayload> {
    const response = await Api.get<ITotalOrderPerDayResponsePayload, ITotalOrderPerDayResponsePayload>(
      'v1/admin/order/total-order-per-day',
      { params: payload }
    );
    return response;
  }

  async getTotalOrders(): Promise<IGetTotalNumberOfPendingOrdersResponsePayload> {
    const response = await Api.get<
      IGetTotalNumberOfPendingOrdersResponsePayload,
      IGetTotalNumberOfPendingOrdersResponsePayload
    >('/v1/order/get-total-orders');
    return response;
  }

  async getTotalPlacedOrders(arg?: PaginationPayload): Promise<IGetTotalPlacedOrdersResponsePayloadArray> {
    const params = paginationFormatter(arg);
    const response = await Api.get<
      IGetTotalPlacedOrdersResponsePayloadArray,
      IGetTotalPlacedOrdersResponsePayloadArray
    >('/v1/orders/placed', {
      params,
    });
    return response;
  }

  async numberOfActiveOrders(): Promise<IGetTotalNumberOfPendingOrdersResponsePayload> {
    const response = await Api.get<
      IGetTotalNumberOfPendingOrdersResponsePayload,
      IGetTotalNumberOfPendingOrdersResponsePayload
    >('/v1/order/total-active-orders');
    return response;
  }

  async numberOfCancelledOrders(): Promise<IGetTotalNumberOfPendingOrdersResponsePayload> {
    const response = await Api.get<
      IGetTotalNumberOfPendingOrdersResponsePayload,
      IGetTotalNumberOfPendingOrdersResponsePayload
    >('/v1/order/total-cancelled-orders');
    return response;
  }

  async numberOfDeliveredOrders(): Promise<IGetTotalNumberOfPendingOrdersResponsePayload> {
    const response = await Api.get<
      IGetTotalNumberOfPendingOrdersResponsePayload,
      IGetTotalNumberOfPendingOrdersResponsePayload
    >('/v1/order/total-delivered-orders');
    return response;
  }

  async numberOfPendingOrders(): Promise<IGetTotalNumberOfPendingOrdersResponsePayload> {
    const response = await Api.get<
      IGetTotalNumberOfPendingOrdersResponsePayload,
      IGetTotalNumberOfPendingOrdersResponsePayload
    >('/v1/order/total-pending-orders');
    return response;
  }

  async getFilteredOrders(payload: GetFilteredOrderPayload): Promise<IGetTotalActiveOrdersResponseArray> {
    const response = await Api.get<IGetTotalActiveOrdersResponseArray, IGetTotalActiveOrdersResponseArray>(
      '/v1/filter/order-filter',
      {
        params: payload,
      }
    );
    return response;
  }
}
