import { PaginationPayload } from '../../../commons/types/pagination.type';
import { RiderRepository } from '../repositories/rider.repo';
import {
  GetFilteredRiderPayload,
  IBlockRiderPayload,
  IBlockRiderResponsePayload,
  IConfirmRiderPayload,
  IConfirmRiderResponsePayload,
  IDeclineRiderPayload,
  IDeclineRiderResponsePayload,
  IDeleteRiderResponsePayload,
  IGetActiveRidersResponsePayload,
  IGetAllActiveRidersResponsePayload,
  IGetAllPendingRidersResponsePayload,
  IGetAllPendingRidersResponsePayloadArray,
  IGetRiderDetailsPayload,
  IGetRiderDetailsResponsePayload,
  IGetRidersByLocationPayload,
  IGetTotalBlockedRidersResponsePayload,
  IGetTotalRidersResponsePayload,
  IGetTotalRidersWithDatePayload,
  TGetRidersByLocationResponsePayload,
} from '../rider_types';

export class RiderService {
  constructor(private readonly riderRepo: RiderRepository) {}

  async getActiveRiders(arg?: PaginationPayload): Promise<IGetAllActiveRidersResponsePayload> {
    const getActiveRidersResponse = await this.riderRepo.getActiveRiders(arg);
    return getActiveRidersResponse;
  }

  async blockRider(payload: IBlockRiderPayload): Promise<IBlockRiderResponsePayload> {
    const blockRiderResponse = await this.riderRepo.blockRider(payload);
    return blockRiderResponse;
  }

  async unblockRider(riderId: string): Promise<IBlockRiderResponsePayload> {
    const blockRiderResponse = await this.riderRepo.unblockRider(riderId);
    return blockRiderResponse;
  }

  async getBlockedRiders(arg?: PaginationPayload): Promise<IGetAllPendingRidersResponsePayload> {
    const getBlockedRidersResponse = await this.riderRepo.getBlockedRiders(arg);
    return getBlockedRidersResponse;
  }

  async confirmRider(payload: IConfirmRiderPayload): Promise<IConfirmRiderResponsePayload> {
    const confirmRiderResponse = await this.riderRepo.confirmRider(payload);
    return confirmRiderResponse;
  }

  async declineRider(payload: IDeclineRiderPayload): Promise<IDeclineRiderResponsePayload> {
    const declineRiderResponse = await this.riderRepo.declineRider(payload);
    return declineRiderResponse;
  }

  async getRiderByLocation(payload: IGetRidersByLocationPayload): Promise<TGetRidersByLocationResponsePayload> {
    const getRiderByLocationResponse = await this.riderRepo.getRiderByLocation(payload);
    return getRiderByLocationResponse;
  }

  async getRiderDetails(payload: IGetRiderDetailsPayload): Promise<IGetRiderDetailsResponsePayload> {
    const getRiderDetailsResponse = await this.riderRepo.getRiderDetails(payload);
    return getRiderDetailsResponse;
  }

  async getPendingRiders(arg?: PaginationPayload): Promise<IGetAllPendingRidersResponsePayloadArray> {
    const getPendingRidersResponse = await this.riderRepo.getPendingRiders(arg);
    return getPendingRidersResponse;
  }

  async getTotalNumberOfBlockedRiders(): Promise<IGetTotalBlockedRidersResponsePayload> {
    const getTotalNumberOfBlockedRidersResponse = await this.riderRepo.getTotalNumberOfBlockedRiders();
    return getTotalNumberOfBlockedRidersResponse;
  }

  async getTotalConfirmedRiders(): Promise<IGetActiveRidersResponsePayload> {
    const getTotalConfirmedRidersResponse = await this.riderRepo.getTotalConfirmedRiders();
    return getTotalConfirmedRidersResponse;
  }

  async getTotalRiders(): Promise<IGetActiveRidersResponsePayload> {
    const getTotalRidersResponse = await this.riderRepo.getTotalRiders();
    return getTotalRidersResponse;
  }

  async geTotalRidersWithinDateRange(payload: IGetTotalRidersWithDatePayload): Promise<IGetTotalRidersResponsePayload> {
    const geTotalRidersWithinDateRangeResponse = await this.riderRepo.geTotalRidersWithinDateRange(payload);
    return geTotalRidersWithinDateRangeResponse;
  }

  async deleteRider(payload: IGetRiderDetailsPayload): Promise<IDeleteRiderResponsePayload> {
    const deleteRiderResponse = await this.riderRepo.deleteRider(payload);
    return deleteRiderResponse;
  }

  async getFilterRiders(payload?: GetFilteredRiderPayload): Promise<IGetAllActiveRidersResponsePayload> {
    const getFilterRidersResponse = await this.riderRepo.getFilterRiders(payload);
    return getFilterRidersResponse;
  }
}
