import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredFleetOwnersPayload, IPendingFleetOwnerState } from '../fleet_owner_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { FleetOwnerRepository } from '../repositories/fleet_owner.repo';
import { FleetOwnerService } from '../services/fleet_service.repo';

const NAMESPACE = 'PENDING_FLEET_OWNERS';

const initialState: IPendingFleetOwnerState = {
  status: HttpStatus.IDLE,
  message: undefined,
  fleetOwners: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const pendingFleetOwners = createAsyncThunk(`${NAMESPACE}/fleetOwners`, async (arg?: PaginationPayload) => {
  const fleetOwnerRepo = new FleetOwnerRepository();
  const fleetOwnerService = new FleetOwnerService(fleetOwnerRepo);
  const response = await fleetOwnerService.getPendingFleetOwners(arg);
  return response;
});

export const getPendingFilteredFleetOwners = createAsyncThunk(
  `${NAMESPACE}/filter-fleet-owners`,
  async (payload: GetFilteredFleetOwnersPayload) => {
    const fleetOwnerRepo = new FleetOwnerRepository();
    const fleetOwnerService = new FleetOwnerService(fleetOwnerRepo);
    const response = await fleetOwnerService.getFilteredFleetOwners(payload);
    return response;
  }
);

const pendingFleetOwnersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetPendingStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pendingFleetOwners.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(pendingFleetOwners.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.fleetOwners = payload.fleetOwners;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(pendingFleetOwners.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getPendingFilteredFleetOwners.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getPendingFilteredFleetOwners.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.fleetOwners = payload.fleetOwners;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getPendingFilteredFleetOwners.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetPendingStatus } = pendingFleetOwnersSlice.actions;

export default pendingFleetOwnersSlice.reducer;
