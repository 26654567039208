import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetTotalFleetOwnersState, IGetTotalFleetOwnersWithDatePayload } from '../fleet_owner_types';
import { FleetOwnerRepository } from '../repositories/fleet_owner.repo';
import { FleetOwnerService } from '../services/fleet_service.repo';

const NAMESPACE = 'TOTAL_FLEET_OWNER';

const initialState: IGetTotalFleetOwnersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalFleetOwners: 0 },
  totalFleetOwnersWithinDateRange: 0,
};

export const totalFleetOwners = createAsyncThunk(`${NAMESPACE}/totalFleetOwners`, async () => {
  const fleetOwnerRepo = new FleetOwnerRepository();
  const fleetOwnerService = new FleetOwnerService(fleetOwnerRepo);
  const response = await fleetOwnerService.getTotalFleetOwners();
  return response;
});

export const totalFleetOwnersWithinDateRange = createAsyncThunk(
  `${NAMESPACE}/fleetOwners`,
  async (payload: IGetTotalFleetOwnersWithDatePayload) => {
    const fleetOwnerRepo = new FleetOwnerRepository();
    const fleetOwnerService = new FleetOwnerService(fleetOwnerRepo);
    const response = await fleetOwnerService.geTotalFleetOwnersWithinDateRange(payload);
    return response.totalFleetOwners;
  }
);

const totalFleetOwnersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalFleetOwners.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalFleetOwners.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(totalFleetOwners.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(totalFleetOwnersWithinDateRange.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalFleetOwnersWithinDateRange.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.totalFleetOwnersWithinDateRange = payload;
    });
    builder.addCase(totalFleetOwnersWithinDateRange.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalFleetOwnersSlice.reducer;
