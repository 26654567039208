import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AddPaymentInitialState, AddPaymentSchema } from '../payment.type';
import { HttpStatus } from '../../../commons';
import { PaymentRepository } from '../repository/payment.repo';
import { PaymentService } from '../service/payment.service';

const NAMESPACE = 'ADD_PAYMENT';

const initialState: AddPaymentInitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const addPayment = createAsyncThunk(NAMESPACE, async (payload: AddPaymentSchema) => {
  const paymentRepo = new PaymentRepository();
  const paymentService = new PaymentService(paymentRepo);
  const response = await paymentService.addPayment(payload);
  return response;
});

const addPaymentSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetPaymentStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPayment.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(addPayment.fulfilled, (state, action) => {
      state.status = HttpStatus.DONE;
      state.response = action.payload;
    });
    builder.addCase(addPayment.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetPaymentStatus } = addPaymentSlice.actions;

export default addPaymentSlice.reducer;
