import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetGeoFenceState } from '../geo_fence_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { GeoFenceRepository } from '../repositories/geo_fence.repo';
import { GeoFenceService } from '../services/geo_fence.service';

const NAMESPACE = 'GET_GEO_FENCE';

const initialState: IGetGeoFenceState = {
  status: HttpStatus.IDLE,
  message: undefined,
  geoFence: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const getGeoFence = createAsyncThunk(`${NAMESPACE}/geoFence`, async (arg?: PaginationPayload) => {
  const geoFenceRepo = new GeoFenceRepository();
  const geoFenceService = new GeoFenceService(geoFenceRepo);
  const response = await geoFenceService.getGeoFence(arg);
  return response;
});

const getGeoFenceSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGeoFence.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getGeoFence.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.geoFence = payload.geoFence;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getGeoFence.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getGeoFenceSlice.reducer;
