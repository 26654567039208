import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  GetFilteredAvertsPayload,
  IGetAllPendingAdvertsResponsePayload,
  IGetTotalAdvertAmountResponsePayload,
  IGetTotalNumberOfAdvertsResponsePayload,
} from '../adverts_types';

export class AdvertsRepository {
  async getExpiredAdverts(arg?: PaginationPayload): Promise<IGetAllPendingAdvertsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetAllPendingAdvertsResponsePayload, IGetAllPendingAdvertsResponsePayload>(
      '/v1/advert/all-expired-adverts',
      {
        params,
      }
    );
    return response;
  }

  async getPendingAdverts(arg?: PaginationPayload): Promise<IGetAllPendingAdvertsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetAllPendingAdvertsResponsePayload, IGetAllPendingAdvertsResponsePayload>(
      '/v1/advert/all-pending-adverts',
      {
        params,
      }
    );
    return response;
  }

  async getTotalAdvertsAmount(): Promise<IGetTotalAdvertAmountResponsePayload> {
    const response = await Api.get<IGetTotalAdvertAmountResponsePayload, IGetTotalAdvertAmountResponsePayload>(
      '/v1/advert/get-total-advert-amount'
    );
    return response;
  }

  async getTotalAdverts(): Promise<IGetTotalNumberOfAdvertsResponsePayload> {
    const response = await Api.get<IGetTotalNumberOfAdvertsResponsePayload, IGetTotalNumberOfAdvertsResponsePayload>(
      '/v1/advert/total-no-of-adverts'
    );
    return response;
  }

  async getFilteredAdverts(payload: GetFilteredAvertsPayload): Promise<IGetAllPendingAdvertsResponsePayload> {
    const response = await Api.get<IGetAllPendingAdvertsResponsePayload, IGetAllPendingAdvertsResponsePayload>(
      '/v1/filter/advert-filter',
      {
        params: payload,
      }
    );
    return response;
  }
}
