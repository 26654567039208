import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetTotalNumberOfPendingOrdersState } from '../orders_types';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'NUMBER_OF_ACTIVE_ORDERS';

const initialState: IGetTotalNumberOfPendingOrdersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalOrders: 0 },
};

export const numberOfActiveOrders = createAsyncThunk(`${NAMESPACE}/orders`, async () => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const order = await orderService.numberOfActiveOrders();
  return order;
});

const numberOfActiveOrdersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(numberOfActiveOrders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(numberOfActiveOrders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(numberOfActiveOrders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error.message ?? String(error);
    });
  },
});

export default numberOfActiveOrdersSlice.reducer;
