import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';

import { Api } from '../../../utils';
import { HttpStatus } from '../../../commons';

const NAMESPACE = 'COUNTRY_STATE';

export interface GetStatesInitialState {
  status: HttpStatus;
  message?: string;
  states: string[];
}

const initialState: GetStatesInitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
  states: [],
};

export const getStates = createAsyncThunk(`${NAMESPACE}/get`, async () => {
  const country = { country: '' };
  const query = qs.stringify({ country });
  const response = await Api.get<string[], string[]>(`/v1/states?${query}`);
  return response;
});

export const stateSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStates.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getStates.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.states = payload;
    });
    builder.addCase(getStates.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default stateSlice.reducer;
