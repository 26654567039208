import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredRiderPayload, IGetAllPendingRidersState } from '../rider_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { RiderRepository } from '../repositories/rider.repo';
import { RiderService } from '../services/rider.service';

const NAMESPACE = 'PENDING_RIDERS';

const initialState: IGetAllPendingRidersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  riders: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const pendingRiders = createAsyncThunk(`${NAMESPACE}/riders`, async (arg?: PaginationPayload) => {
  const riderRepo = new RiderRepository();
  const riderService = new RiderService(riderRepo);
  const response = await riderService.getPendingRiders(arg);
  return response.pendingRiders;
});

export const getPendingFilteredRiders = createAsyncThunk(
  `${NAMESPACE}/filter-rider`,
  async (payload?: GetFilteredRiderPayload) => {
    const riderRepo = new RiderRepository();
    const riderService = new RiderService(riderRepo);
    const response = await riderService.getFilterRiders(payload);
    return response;
  }
);

const pendingRidersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(pendingRiders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(pendingRiders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.riders = payload.riders;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(pendingRiders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getPendingFilteredRiders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getPendingFilteredRiders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.riders = payload.riders;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getPendingFilteredRiders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default pendingRidersSlice.reducer;
