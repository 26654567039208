import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../utils';

const NAMESPACE = 'SWITCH_SCREEN';

const initialState = {
  page: PAGES.profile,
};

export const profileSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePage } = profileSlice.actions;

export default profileSlice.reducer;
