import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './chakraui.config';
import { Provider } from 'react-redux';
import { store } from './redux/app/store';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallBackUI } from './components/errors/error_fallback_UI';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <ErrorBoundary
          FallbackComponent={ErrorFallBackUI}
          onError={() => console.log('An error occurred!')}
          onReset={() => {
            window.location.reload();
          }}
        >
          <App />
        </ErrorBoundary>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
