import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IBlockFleetOwnerPayload, IBlockFleetOwnerPayloadState } from '../fleet_owner_types';
import { FleetOwnerRepository } from '../repositories/fleet_owner.repo';
import { FleetOwnerService } from '../services/fleet_service.repo';

const NAMESPACE = 'BLOCK_FLEET_OWNER';

const initialState: IBlockFleetOwnerPayloadState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const blockFleetOwner = createAsyncThunk(
  `${NAMESPACE}/blockFleetOwner`,
  async (payload: IBlockFleetOwnerPayload) => {
    const fleetOwnerRepo = new FleetOwnerRepository();
    const fleetOwnerService = new FleetOwnerService(fleetOwnerRepo);
    const response = await fleetOwnerService.blockFleetOwner(payload);
    return response;
  }
);

const blockFleetOwnerSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(blockFleetOwner.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(blockFleetOwner.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(blockFleetOwner.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = blockFleetOwnerSlice.actions;

export default blockFleetOwnerSlice.reducer;
