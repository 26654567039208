import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../payment.type';

const NAMESPACE = 'SWITCH_SCREEN';

const initialState = {
  page: PAGES.paymentForm,
};

export const paymentSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    switchTab: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { switchTab } = paymentSlice.actions;

export default paymentSlice.reducer;
