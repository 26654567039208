import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetGeoFenceDetailsState, IGetGeoFenceDetailsPayload } from '../geo_fence_types';
import { GeoFenceRepository } from '../repositories/geo_fence.repo';
import { GeoFenceService } from '../services/geo_fence.service';

const NAMESPACE = 'DELETE_FENCE_DETAILS';

const initialState: IGetGeoFenceDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const deleteGeoFence = createAsyncThunk(`${NAMESPACE}/geoFence`, async (payload: IGetGeoFenceDetailsPayload) => {
  const geoFenceRepo = new GeoFenceRepository();
  const geoFenceService = new GeoFenceService(geoFenceRepo);
  const response = await geoFenceService.deleteGeoFence(payload);
  return response;
});

export const deleteGeoFenceSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetDeleteGeoFenceStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteGeoFence.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(deleteGeoFence.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(deleteGeoFence.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetDeleteGeoFenceStatus } = deleteGeoFenceSlice.actions;

export default deleteGeoFenceSlice.reducer;
