import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetRiderDetailsState, IGetRiderDetailsPayload } from '../rider_types';
import { RiderRepository } from '../repositories/rider.repo';
import { RiderService } from '../services/rider.service';

const NAMESPACE = 'GET_RIDER_DETAILS';

const initialState: IGetRiderDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getRiderDetails = createAsyncThunk(`${NAMESPACE}/rider`, async (payload: IGetRiderDetailsPayload) => {
  const riderRepo = new RiderRepository();
  const riderService = new RiderService(riderRepo);
  const response = await riderService.getRiderDetails(payload);
  return response;
});

export const getRiderDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetRiderStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRiderDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getRiderDetails.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getRiderDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetRiderStatus } = getRiderDetailsSlice.actions;

export default getRiderDetailsSlice.reducer;
