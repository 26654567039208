import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetAdminCommissionDetailsState, GetAdminCommissionDetailsPayload } from '../admin_commission_types';
import { AdminCommissionRepository } from '../repositories/admin_commission.repo';
import { AdminCommissionService } from '../services/admin_commission.service';

const NAMESPACE = 'GET_ADMIN_COMMISSION_DETAILS';

const initialState: GetAdminCommissionDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getAdminCommissionDetails = createAsyncThunk(
  `${NAMESPACE}/commission`,
  async (payload: GetAdminCommissionDetailsPayload) => {
    const adminCommissionRepo = new AdminCommissionRepository();
    const adminCommissionService = new AdminCommissionService(adminCommissionRepo);
    const response = await adminCommissionService.getAdminCommissionDetails(payload);
    return response;
  }
);

const getAdminCommissionDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminCommissionDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getAdminCommissionDetails.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getAdminCommissionDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = getAdminCommissionDetailsSlice.actions;

export default getAdminCommissionDetailsSlice.reducer;
