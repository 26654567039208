import { PaginationPayload } from '../../../commons/types/pagination.type';
import {
  GetFilteredAvertsPayload,
  IGetAllPendingAdvertsResponsePayload,
  IGetTotalAdvertAmountResponsePayload,
  IGetTotalNumberOfAdvertsResponsePayload,
} from '../adverts_types';
import { AdvertsRepository } from '../repositories/adverts.repo';

export class AdvertsService {
  constructor(private readonly advertsRepo: AdvertsRepository) {}

  async getExpiredAdverts(arg?: PaginationPayload): Promise<IGetAllPendingAdvertsResponsePayload> {
    const getExpiredAdvertsResponse = await this.advertsRepo.getExpiredAdverts(arg);
    return getExpiredAdvertsResponse;
  }

  async getPendingAdverts(arg?: PaginationPayload): Promise<IGetAllPendingAdvertsResponsePayload> {
    const getPendingAdvertsResponse = await this.advertsRepo.getPendingAdverts(arg);
    return getPendingAdvertsResponse;
  }

  async getTotalAdvertsAmount(): Promise<IGetTotalAdvertAmountResponsePayload> {
    const getTotalAdvertsAmountResponse = await this.advertsRepo.getTotalAdvertsAmount();
    return getTotalAdvertsAmountResponse;
  }

  async getTotalAdverts(): Promise<IGetTotalNumberOfAdvertsResponsePayload> {
    const getTotalAdvertsResponse = await this.advertsRepo.getTotalAdverts();
    return getTotalAdvertsResponse;
  }

  async getFilteredAdverts(payload: GetFilteredAvertsPayload): Promise<IGetAllPendingAdvertsResponsePayload> {
    const getFilteredAdvertsResponse = await this.advertsRepo.getFilteredAdverts(payload);
    return getFilteredAdvertsResponse;
  }
}
