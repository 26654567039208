import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  GetCouponPayload,
  GetFilteredCouponByDatePayload,
  GetTotalCouponResponsePayload,
  ICreateCouponPayload,
  ICreateCouponResponsePayload,
} from '../coupon_types';

export class CouponRepository {
  async createCoupon(payload: ICreateCouponPayload): Promise<ICreateCouponResponsePayload> {
    const response = await Api.post<ICreateCouponResponsePayload, ICreateCouponResponsePayload>(
      'v1/coupon/create',
      payload
    );
    return response;
  }

  async getNewCoupons(arg?: PaginationPayload): Promise<GetCouponPayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetCouponPayload, GetCouponPayload>('/v1/coupon/fetch-new-coupons', {
      params,
    });
    return response;
  }

  async getTotalCoupon(): Promise<GetTotalCouponResponsePayload> {
    const response = await Api.get<GetTotalCouponResponsePayload, GetTotalCouponResponsePayload>(
      '/v1/coupon/total-coupons'
    );
    return response;
  }

  async getFilteredCoupon(payload: GetFilteredCouponByDatePayload): Promise<GetCouponPayload> {
    const response = await Api.get<GetCouponPayload, GetCouponPayload>('/v1/filter/coupon-filter', {
      params: payload,
    });
    return response;
  }
}
