import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  GetFilteredFleetOwnersPayload,
  GetTotalBlockedFleetOwnersPayload,
  IBlockFleetOwnerPayload,
  IBlockFleetOwnerResponsePayload,
  IConfirmFleetOwnerPayload,
  IConfirmFleetOwnerResponsePayload,
  IDeclineFleetOwnerPayload,
  IGetTotalActiveFleetOwnersPayload,
  IGetTotalFleetOwnersDetailsPayload,
  IGetTotalFleetOwnersDetailsResponsePayload,
  IGetTotalFleetOwnersPayload,
  IGetTotalFleetOwnersResponsePayload,
  IGetTotalFleetOwnersWithDatePayload,
  IPendingFleetOwnerResponsePayload,
} from '../fleet_owner_types';

export class FleetOwnerRepository {
  async blockFleetOwner(payload: IBlockFleetOwnerPayload): Promise<IBlockFleetOwnerResponsePayload> {
    const response = await Api.put<IBlockFleetOwnerResponsePayload, IBlockFleetOwnerResponsePayload>(
      '/v1/fleet-owner/block-fleet-owner',
      payload
    );
    return response;
  }

  async confirmFleetOwner(payload: IConfirmFleetOwnerPayload): Promise<IConfirmFleetOwnerResponsePayload> {
    const response = await Api.put<IConfirmFleetOwnerResponsePayload, IConfirmFleetOwnerResponsePayload>(
      'v1/fleet-owner/confirm-fleet-owner',
      payload
    );
    return response;
  }

  async declineFleetOwner(payload: IDeclineFleetOwnerPayload): Promise<IConfirmFleetOwnerResponsePayload> {
    const response = await Api.put<IConfirmFleetOwnerResponsePayload, IConfirmFleetOwnerResponsePayload>(
      'v1/fleet-owner/decline-fleet-owner',
      payload
    );
    return response;
  }

  async getActiveFleetOwners(arg?: PaginationPayload): Promise<IPendingFleetOwnerResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IPendingFleetOwnerResponsePayload, IPendingFleetOwnerResponsePayload>(
      '/v1/fleet-owner/active-fleet-owners',
      {
        params,
      }
    );
    return response;
  }

  async getBlockedFleetOwners(arg?: PaginationPayload): Promise<IPendingFleetOwnerResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IPendingFleetOwnerResponsePayload, IPendingFleetOwnerResponsePayload>(
      '/v1/fleet-owner/blocked-fleet-owners',
      {
        params,
      }
    );
    return response;
  }

  async getFleetOwnerDetails(
    payload: IGetTotalFleetOwnersDetailsPayload
  ): Promise<IGetTotalFleetOwnersDetailsResponsePayload> {
    const response = await Api.get<
      IGetTotalFleetOwnersDetailsResponsePayload,
      IGetTotalFleetOwnersDetailsResponsePayload
    >('v1/fleet-owner/view-details', { params: payload });
    return response;
  }

  async getPendingFleetOwners(arg?: PaginationPayload): Promise<IPendingFleetOwnerResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IPendingFleetOwnerResponsePayload, IPendingFleetOwnerResponsePayload>(
      '/v1/fleet-owner/pending-fleet-owners',
      {
        params,
      }
    );
    return response;
  }

  async getTotalNumberOfActiveFleetOwners(): Promise<IGetTotalActiveFleetOwnersPayload> {
    const response = await Api.get<IGetTotalActiveFleetOwnersPayload, IGetTotalActiveFleetOwnersPayload>(
      '/v1/fleet-owner/total-active'
    );
    return response;
  }

  async getTotalFleetOwners(): Promise<IGetTotalFleetOwnersPayload> {
    const response = await Api.get<IGetTotalFleetOwnersPayload, IGetTotalFleetOwnersPayload>(
      '/v1/fleet-owner/total-fleet-owners'
    );
    return response;
  }

  async geTotalFleetOwnersWithinDateRange(
    payload: IGetTotalFleetOwnersWithDatePayload
  ): Promise<IGetTotalFleetOwnersResponsePayload> {
    const response = await Api.get<IGetTotalFleetOwnersResponsePayload, IGetTotalFleetOwnersResponsePayload>(
      'v1/fleet-owner/total-fleet-owners-with-date',
      { params: payload }
    );
    return response;
  }

  async getFilteredFleetOwners(payload: GetFilteredFleetOwnersPayload): Promise<IPendingFleetOwnerResponsePayload> {
    const response = await Api.get<IPendingFleetOwnerResponsePayload, IPendingFleetOwnerResponsePayload>(
      '/v1/filter/fleet-owner-filter',
      {
        params: payload,
      }
    );
    return response;
  }

  async getTotalNumberOfBlockedFleetOwners(): Promise<GetTotalBlockedFleetOwnersPayload> {
    const response = await Api.get<GetTotalBlockedFleetOwnersPayload, GetTotalBlockedFleetOwnersPayload>(
      '/v1/fleet-owner/total-blocked'
    );
    return response;
  }
}
