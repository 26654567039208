import { PaginationPayload } from '../../../commons/types/pagination.type';
import { VendorRepository } from '../repositories/vendor.repo';
import {
  GetFilteredVendorPayload,
  GetTotalVendorsWithDatePayload,
  GetTotalVendorsWithDateResponsePayload,
  IApproveVendorPayload,
  IApproveVendorResponsePayload,
  IBlockVendorPayload,
  IBlockVendorResponsePayload,
  IDeclineVendorPayload,
  IDeclineVendorResponsePayload,
  IDeleteVendorResponsePayload,
  IGetActiveVendorsResponsePayload,
  IGetPendingVendorsResponsePayload,
  IGetTotalBlockedVendorsResponsePayload,
  IGetTotalVendorsResponsePayload,
  IGetVendorDetailsPayload,
  VendorDetails,
} from '../vendor_types';

export class VendorService {
  constructor(private readonly vendorRepo: VendorRepository) {}

  async getNumberOfActiveVendors(): Promise<IGetActiveVendorsResponsePayload> {
    const getNumberOfActiveVendorsResponse = await this.vendorRepo.getNumberOfActiveVendors();
    return getNumberOfActiveVendorsResponse;
  }

  async blockVendor(payload: IBlockVendorPayload): Promise<IBlockVendorResponsePayload> {
    const blockVendorResponse = await this.vendorRepo.blockVendor(payload);
    return blockVendorResponse;
  }

  async unBlockVendor(payload: { vendorId: string }): Promise<IBlockVendorResponsePayload> {
    const blockVendorResponse = await this.vendorRepo.unBlockVendor(payload);
    return blockVendorResponse;
  }

  async confirmVendor(payload: IApproveVendorPayload): Promise<IApproveVendorResponsePayload> {
    const confirmVendorResponse = await this.vendorRepo.confirmVendor(payload);
    return confirmVendorResponse;
  }

  async declineVendor(payload: IDeclineVendorPayload): Promise<IDeclineVendorResponsePayload> {
    const declineVendorResponse = await this.vendorRepo.declineVendor(payload);
    return declineVendorResponse;
  }

  async getVendorDetails(payload: IGetVendorDetailsPayload): Promise<VendorDetails> {
    const getVendorDetailsResponse = await this.vendorRepo.getVendorDetails(payload);
    return getVendorDetailsResponse;
  }

  async getListOfApprovedVendors(arg?: PaginationPayload): Promise<IGetPendingVendorsResponsePayload> {
    const getListOfApprovedVendorsResponse = await this.vendorRepo.getListOfApprovedVendors(arg);
    return getListOfApprovedVendorsResponse;
  }

  async getListOfBlockedVendors(arg?: PaginationPayload): Promise<IGetPendingVendorsResponsePayload> {
    const getListOfBlockedVendorsResponse = await this.vendorRepo.getListOfBlockedVendors(arg);
    return getListOfBlockedVendorsResponse;
  }

  async getListOfPendingVendors(arg?: PaginationPayload): Promise<IGetPendingVendorsResponsePayload> {
    const getListOfPendingVendorsResponse = await this.vendorRepo.getListOfPendingVendors(arg);
    return getListOfPendingVendorsResponse;
  }

  async getTotalNumberOfBlockedVendors(): Promise<IGetTotalBlockedVendorsResponsePayload> {
    const getTotalNumberOfBlockedVendorsResponse = await this.vendorRepo.getTotalNumberOfBlockedVendors();
    return getTotalNumberOfBlockedVendorsResponse;
  }

  async getNumberOfVendors(): Promise<IGetTotalVendorsResponsePayload> {
    const getNumberOfVendorsResponse = await this.vendorRepo.getNumberOfVendors();
    return getNumberOfVendorsResponse;
  }

  async getTotalVendorsWithinDateRange(
    payload: GetTotalVendorsWithDatePayload
  ): Promise<GetTotalVendorsWithDateResponsePayload> {
    const getTotalVendorsWithinDateRangeResponse = await this.vendorRepo.getTotalVendorsWithinDateRange(payload);
    return getTotalVendorsWithinDateRangeResponse;
  }

  async deleteVendor(payload: IGetVendorDetailsPayload): Promise<IDeleteVendorResponsePayload> {
    const deleteVendorResponse = await this.vendorRepo.deleteVendor(payload);
    return deleteVendorResponse;
  }

  async getFilteredVendors(payload: GetFilteredVendorPayload): Promise<IGetPendingVendorsResponsePayload> {
    const getFilteredVendorsResponse = await this.vendorRepo.getFilteredVendors(payload);
    return getFilteredVendorsResponse;
  }
}
