import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  GetFilteredAccountingPayload,
  IDisbursePaymentPayload,
  IDisbursePaymentResponsePayload,
  IGetPendingWithdrawalResponsePayload,
} from '../accounting_types';

export class AccountingRepository {
  async getPayoutHistory(arg?: PaginationPayload): Promise<IGetPendingWithdrawalResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetPendingWithdrawalResponsePayload, IGetPendingWithdrawalResponsePayload>(
      '/v1/withdrawal/history',
      {
        params,
      }
    );
    return response;
  }

  async getPendingWithdrawal(arg?: PaginationPayload): Promise<IGetPendingWithdrawalResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetPendingWithdrawalResponsePayload, IGetPendingWithdrawalResponsePayload>(
      '/v1/withdrawal/pending',
      {
        params,
      }
    );
    return response;
  }

  async disbursePayment(payload: IDisbursePaymentPayload): Promise<IDisbursePaymentResponsePayload> {
    const response = await Api.post<IDisbursePaymentResponsePayload, IDisbursePaymentResponsePayload>(
      '/v1/withdrawal/disburse',
      payload
    );
    return response;
  }

  async getFilteredAccounting(payload: GetFilteredAccountingPayload): Promise<IGetPendingWithdrawalResponsePayload> {
    const response = await Api.get<IGetPendingWithdrawalResponsePayload, IGetPendingWithdrawalResponsePayload>(
      '/v1/filter/accounting-filter',
      {
        params: payload,
      }
    );
    return response;
  }
}
