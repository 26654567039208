import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  GetFilteredStaffsPayload,
  IAddStaffPayload,
  IAddStaffResponsePayload,
  IGetAllStaffsResponsePayload,
  IGetTotalNumberOfStaffs,
} from '../sub_admin_types';

export class SubAdminRepository {
  async getAllStaffs(arg?: PaginationPayload): Promise<IGetAllStaffsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetAllStaffsResponsePayload, IGetAllStaffsResponsePayload>('/v1/admin/sub-admins', {
      params,
    });
    return response;
  }

  async onBoardStaff(payload: IAddStaffPayload): Promise<IAddStaffResponsePayload> {
    const response = await Api.post<IAddStaffResponsePayload, IAddStaffResponsePayload>('/v1/admin', payload);
    return response;
  }

  async totalStaffs(): Promise<IGetTotalNumberOfStaffs> {
    const response = await Api.get<IGetTotalNumberOfStaffs, IGetTotalNumberOfStaffs>('/v1/admin/total-sub-admin');
    return response;
  }

  async getFilteredStaff(payload: GetFilteredStaffsPayload): Promise<IGetAllStaffsResponsePayload> {
    const response = await Api.get<IGetAllStaffsResponsePayload, IGetAllStaffsResponsePayload>(
      '/v1/filter/sub-admin-filter',
      {
        params: payload,
      }
    );
    return response;
  }
}
