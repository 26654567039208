import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus, setCredentials } from '../../../commons';
import { GetUserEmailPayload, GetUserEmailState } from '../auth_types';
import { AuthenticationRepository } from '../repositories/authentication.repo';
import { AuthenticationService } from '../services/authentication.service';

const NAMESPACE = 'ADMIN_LOGIN';

const initialState: GetUserEmailState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getUserEmail = createAsyncThunk(`${NAMESPACE}/getUserEmail`, async (payload: GetUserEmailPayload) => {
  const authenticationRepo = new AuthenticationRepository();
  const authenticationService = new AuthenticationService(authenticationRepo);
  const response = await authenticationService.getUserEmail(payload);
  return response;
});

const getUserEmailSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserEmail.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getUserEmail.fulfilled, (state, { payload }) => {
      setCredentials(payload.token, payload.adminId);
      state.status = HttpStatus.DONE;
    });
    builder.addCase(getUserEmail.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetAuthState } = getUserEmailSlice.actions;

export default getUserEmailSlice.reducer;
