import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  GetFilteredVendorPayload,
  GetTotalVendorsWithDatePayload,
  GetTotalVendorsWithDateResponsePayload,
  IApproveVendorPayload,
  IApproveVendorResponsePayload,
  IBlockVendorPayload,
  IBlockVendorResponsePayload,
  IDeclineVendorPayload,
  IDeclineVendorResponsePayload,
  IDeleteVendorResponsePayload,
  IGetActiveVendorsResponsePayload,
  IGetPendingVendorsResponsePayload,
  IGetTotalBlockedVendorsResponsePayload,
  IGetTotalVendorsResponsePayload,
  IGetVendorDetailsPayload,
  VendorDetails,
} from '../vendor_types';

export class VendorRepository {
  async getNumberOfActiveVendors(): Promise<IGetActiveVendorsResponsePayload> {
    const response = await Api.get<IGetActiveVendorsResponsePayload, IGetActiveVendorsResponsePayload>(
      '/v1/vendor/total-approved-vendors'
    );
    return response;
  }

  async blockVendor(payload: IBlockVendorPayload): Promise<IBlockVendorResponsePayload> {
    const response = await Api.put<IBlockVendorResponsePayload, IBlockVendorResponsePayload>(
      '/v2/vendor/block-vendor',
      payload
    );
    return response;
  }

  async unBlockVendor(payload: { vendorId: string }): Promise<IBlockVendorResponsePayload> {
    const response = await Api.put<IBlockVendorResponsePayload, IBlockVendorResponsePayload>(
      '/v2/vendor/unblock-vendor',
      payload
    );
    return response;
  }

  async confirmVendor(payload: IApproveVendorPayload): Promise<IApproveVendorResponsePayload> {
    const response = await Api.put<IApproveVendorResponsePayload, IApproveVendorResponsePayload>(
      '/v1/vendor/approve',
      payload
    );
    return response;
  }

  async declineVendor(payload: IDeclineVendorPayload): Promise<IDeclineVendorResponsePayload> {
    const response = await Api.put<IDeclineVendorResponsePayload, IDeclineVendorResponsePayload>(
      '/v1/vendor/decline',
      payload
    );
    return response;
  }

  async getVendorDetails(payload: IGetVendorDetailsPayload): Promise<VendorDetails> {
    const response = await Api.get<VendorDetails, VendorDetails>(`v2/vendor/${payload.vendorId}`);
    return response;
  }

  async getListOfApprovedVendors(arg?: PaginationPayload): Promise<IGetPendingVendorsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetPendingVendorsResponsePayload, IGetPendingVendorsResponsePayload>(
      '/v1/vendors/approved',
      {
        params,
      }
    );
    return response;
  }

  async getListOfBlockedVendors(arg?: PaginationPayload): Promise<IGetPendingVendorsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetPendingVendorsResponsePayload, IGetPendingVendorsResponsePayload>(
      '/v1/vendor/blocked-vendors',
      {
        params,
      }
    );
    return response;
  }

  async getListOfPendingVendors(arg?: PaginationPayload): Promise<IGetPendingVendorsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetPendingVendorsResponsePayload, IGetPendingVendorsResponsePayload>(
      '/v1/vendors/pending',
      {
        params,
      }
    );
    return response;
  }

  async getTotalNumberOfBlockedVendors(): Promise<IGetTotalBlockedVendorsResponsePayload> {
    const response = await Api.get<IGetTotalBlockedVendorsResponsePayload, IGetTotalBlockedVendorsResponsePayload>(
      '/v1/vendor/total-blocked'
    );
    return response;
  }

  async getNumberOfVendors(): Promise<IGetTotalVendorsResponsePayload> {
    const response = await Api.get<IGetTotalVendorsResponsePayload, IGetTotalVendorsResponsePayload>(
      '/v1/vendor/email-verified'
    );
    return response;
  }

  async getTotalVendorsWithinDateRange(
    payload: GetTotalVendorsWithDatePayload
  ): Promise<GetTotalVendorsWithDateResponsePayload> {
    const response = await Api.get<GetTotalVendorsWithDateResponsePayload, GetTotalVendorsWithDateResponsePayload>(
      'v1/vendor/total-vendors-with-date',
      { params: payload }
    );
    return response;
  }

  async deleteVendor(payload: IGetVendorDetailsPayload): Promise<IDeleteVendorResponsePayload> {
    const response = await Api.delete<IDeleteVendorResponsePayload, IDeleteVendorResponsePayload>(
      'v1/vendor/delete-vendor',
      { params: payload }
    );
    return response;
  }

  async getFilteredVendors(payload: GetFilteredVendorPayload): Promise<IGetPendingVendorsResponsePayload> {
    const response = await Api.get<IGetPendingVendorsResponsePayload, IGetPendingVendorsResponsePayload>(
      '/v1/filter/vendor-filter',
      {
        params: payload,
      }
    );
    return response;
  }
}
