import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredRiderPayload, IGetAllActiveRidersState } from '../rider_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { RiderRepository } from '../repositories/rider.repo';
import { RiderService } from '../services/rider.service';

const NAMESPACE = 'ACTIVE_RIDERS';

const initialState: IGetAllActiveRidersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  riders: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const activeRiders = createAsyncThunk(`${NAMESPACE}/activeRiders`, async (arg?: PaginationPayload) => {
  const riderRepo = new RiderRepository();
  const riderService = new RiderService(riderRepo);
  const response = await riderService.getActiveRiders(arg);
  return response;
});

export const getActiveFilteredRiders = createAsyncThunk(
  `${NAMESPACE}/filter-rider`,
  async (payload?: GetFilteredRiderPayload) => {
    const riderRepo = new RiderRepository();
    const riderService = new RiderService(riderRepo);
    const response = await riderService.getFilterRiders(payload);
    return response;
  }
);

const activeRidersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(activeRiders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(activeRiders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.riders = payload.riders;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(activeRiders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getActiveFilteredRiders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getActiveFilteredRiders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.riders = payload.riders;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getActiveFilteredRiders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default activeRidersSlice.reducer;
