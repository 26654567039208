import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetTotalNumberOfStaffsState } from '../sub_admin_types';
import { SubAdminRepository } from '../repositories/sub_admin.repo';
import { SubAdminService } from '../services/sub_admin.service';

const NAMESPACE = 'TOTAL_STAFFS';

const initialState: IGetTotalNumberOfStaffsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalSubAdmin: 0 },
};

export const totalStaffs = createAsyncThunk(`${NAMESPACE}/staffs`, async () => {
  const subAdminRepo = new SubAdminRepository();
  const subAdminService = new SubAdminService(subAdminRepo);
  const response = await subAdminService.totalStaffs();
  return response.totalSubAdmin;
});

const totalStaffsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalStaffs.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalStaffs.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response!.totalSubAdmin = payload;
    });
    builder.addCase(totalStaffs.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalStaffsSlice.reducer;
