import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredAvertsPayload, IGetAllPendingAdvertsState } from '../adverts_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { AdvertsRepository } from '../repositories/adverts.repo';
import { AdvertsService } from '../services/adverts.service';

const NAMESPACE = 'GET_ALL_PENDING_ADVERTS';

const initialState: IGetAllPendingAdvertsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  adverts: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const getPendingAdverts = createAsyncThunk(`${NAMESPACE}/adverts`, async (arg?: PaginationPayload) => {
  const advertsRepo = new AdvertsRepository();
  const advertsService = new AdvertsService(advertsRepo);
  const response = await advertsService.getPendingAdverts(arg);
  return response;
});

export const getPendingFilteredAdverts = createAsyncThunk(
  `${NAMESPACE}/adverts-filter`,
  async (payload: GetFilteredAvertsPayload) => {
    const advertsRepo = new AdvertsRepository();
    const advertsService = new AdvertsService(advertsRepo);
    const response = await advertsService.getFilteredAdverts(payload);
    return response;
  }
);

const getPendingAdvertsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPendingAdverts.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getPendingAdverts.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.adverts = payload.adverts;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getPendingAdverts.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getPendingFilteredAdverts.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getPendingFilteredAdverts.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.adverts = payload.adverts;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getPendingFilteredAdverts.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getPendingAdvertsSlice.reducer;
