import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IDeclineRiderState, IGetRiderDetailsPayload } from '../rider_types';
import { RiderRepository } from '../repositories/rider.repo';
import { RiderService } from '../services/rider.service';

const NAMESPACE = 'DELETE_RIDER';

const initialState: IDeclineRiderState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const deleteRider = createAsyncThunk(`${NAMESPACE}/rider`, async (payload: IGetRiderDetailsPayload) => {
  const riderRepo = new RiderRepository();
  const riderService = new RiderService(riderRepo);
  const response = await riderService.deleteRider(payload);
  return response;
});

export const deleteRiderSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetDeleteRiderStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteRider.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(deleteRider.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(deleteRider.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetDeleteRiderStatus } = deleteRiderSlice.actions;

export default deleteRiderSlice.reducer;
