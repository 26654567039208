import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetActiveRidersState } from '../rider_types';
import { RiderRepository } from '../repositories/rider.repo';
import { RiderService } from '../services/rider.service';

const NAMESPACE = 'TOTAL_CONFIRMED_RIDERS';

const initialState: IGetActiveRidersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalRiders: 0 },
};

export const totalConfirmedRiders = createAsyncThunk(`${NAMESPACE}/totalRiders`, async () => {
  const riderRepo = new RiderRepository();
  const riderService = new RiderService(riderRepo);
  const response = await riderService.getTotalConfirmedRiders();
  return response;
});

const totalConfirmedRidersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalConfirmedRiders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalConfirmedRiders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(totalConfirmedRiders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalConfirmedRidersSlice.reducer;
