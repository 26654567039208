import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'UNBLOCK_VENDORS';

interface InitialState {
  status: HttpStatus;
  message?: string;
}

const initialState: InitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const unblockVendor = createAsyncThunk(`${NAMESPACE}/patch`, async (payload: { vendorId: string }) => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.unBlockVendor(payload);
  return response;
});

const unblockVendorSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetUnblockVendorStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(unblockVendor.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(unblockVendor.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(unblockVendor.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetUnblockVendorStatus } = unblockVendorSlice.actions;

export default unblockVendorSlice.reducer;
