import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetTotalBlockedRidersState } from '../rider_types';
import { RiderRepository } from '../repositories/rider.repo';
import { RiderService } from '../services/rider.service';

const NAMESPACE: string = 'GET_BLOCKED_RIDERS';

const initialState: IGetTotalBlockedRidersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { total: 0 },
};

export const totalNumberOfBlockedRiders = createAsyncThunk(`${NAMESPACE}/riders`, async () => {
  const riderRepo = new RiderRepository();
  const riderService = new RiderService(riderRepo);
  const response = riderService.getTotalNumberOfBlockedRiders();
  return response;
});

export const totalNumberOfBlockedRidersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalNumberOfBlockedRiders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalNumberOfBlockedRiders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(totalNumberOfBlockedRiders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalNumberOfBlockedRidersSlice.reducer;
