import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IBlockRiderState, IBlockRiderPayload } from '../rider_types';
import { RiderRepository } from '../repositories/rider.repo';
import { RiderService } from '../services/rider.service';

const NAMESPACE = 'BLOCK_RIDER';

const initialState: IBlockRiderState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const blockRider = createAsyncThunk(`${NAMESPACE}/put`, async (payload: IBlockRiderPayload) => {
  const riderRepo = new RiderRepository();
  const riderService = new RiderService(riderRepo);
  const response = await riderService.blockRider(payload);
  return response;
});

const blockRiderSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetBlockRiderStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(blockRider.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(blockRider.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(blockRider.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetBlockRiderStatus } = blockRiderSlice.actions;

export default blockRiderSlice.reducer;
