import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetTotalNumberOfAdvertsState } from '../adverts_types';
import { AdvertsRepository } from '../repositories/adverts.repo';
import { AdvertsService } from '../services/adverts.service';

const NAMESPACE = 'TOTAL_NUMBER_OF_ADVERTS';

const initialState: IGetTotalNumberOfAdvertsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalAdvert: 0 },
};

export const totalAdverts = createAsyncThunk(`${NAMESPACE}/adverts`, async () => {
  const advertsRepo = new AdvertsRepository();
  const advertsService = new AdvertsService(advertsRepo);
  const response = await advertsService.getTotalAdverts();
  return response;
});

const totalAdvertsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalAdverts.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalAdverts.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(totalAdverts.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalAdvertsSlice.reducer;
