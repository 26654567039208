import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IDeclineVendorState, IDeclineVendorPayload } from '../vendor_types';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'DECLINE_VENDORS';

const initialState: IDeclineVendorState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const declineVendor = createAsyncThunk(`${NAMESPACE}/vendor`, async (payload: IDeclineVendorPayload) => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.declineVendor(payload);
  return response;
});

const declineVendorSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetDeclineVendorStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(declineVendor.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(declineVendor.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(declineVendor.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetDeclineVendorStatus } = declineVendorSlice.actions;

export default declineVendorSlice.reducer;
