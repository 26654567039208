import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { InitialState, EditAdminCommissionFormPayload } from '../admin_commission_types';
import { AdminCommissionRepository } from '../repositories/admin_commission.repo';
import { AdminCommissionService } from '../services/admin_commission.service';

const NAMESPACE = 'EDIT_ADMIN_COMMISSION';

const initialState: InitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const editAdminCommission = createAsyncThunk(
  `${NAMESPACE}/commission`,
  async (payload: EditAdminCommissionFormPayload) => {
    const adminCommissionRepo = new AdminCommissionRepository();
    const adminCommissionService = new AdminCommissionService(adminCommissionRepo);
    const response = await adminCommissionService.editAdminCommission(payload);
    return response;
  }
);

const editAdminCommissionSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editAdminCommission.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(editAdminCommission.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(editAdminCommission.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = editAdminCommissionSlice.actions;

export default editAdminCommissionSlice.reducer;
