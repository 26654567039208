import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetRiderAdminCommissionState } from '../admin_commission_types';
import { AdminCommissionRepository } from '../repositories/admin_commission.repo';
import { AdminCommissionService } from '../services/admin_commission.service';

const NAMESPACE = 'GET_RIDER_ADMIN_COMMISSION';

const initialState: GetRiderAdminCommissionState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalRiderCommission: 0, riderCommission: 0, fleetOwnerCommission: 0 },
};

export const getRiderAdminCommission = createAsyncThunk(`${NAMESPACE}/commission`, async () => {
  const adminCommissionRepo = new AdminCommissionRepository();
  const adminCommissionService = new AdminCommissionService(adminCommissionRepo);
  const response = await adminCommissionService.getRiderAdminCommission();
  return response;
});

const getRiderAdminCommissionSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRiderAdminCommission.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getRiderAdminCommission.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getRiderAdminCommission.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getRiderAdminCommissionSlice.reducer;
