import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IGetOrderDetailsState, IGetOrderDetailsPayload } from '../orders_types';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'GET_ORDER_DETAILS';

const initialState: IGetOrderDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getOrderDetails = createAsyncThunk(`${NAMESPACE}/rider`, async (payload: IGetOrderDetailsPayload) => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const order = await orderService.getOrderDetails(payload);
  return order;
});

export const getOrderDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetOrderStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getOrderDetails.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getOrderDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetOrderStatus } = getOrderDetailsSlice.actions;

export default getOrderDetailsSlice.reducer;
