import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { AddNewRidersToGeoFenceState, AddNewRidersToGeoFencePayload } from '../geo_fence_types';
import { GeoFenceRepository } from '../repositories/geo_fence.repo';
import { GeoFenceService } from '../services/geo_fence.service';

const NAMESPACE = 'ADD_RIDER_TO_GEO_FENCE';

const initialState: AddNewRidersToGeoFenceState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const addRiderToGeoFence = createAsyncThunk(
  `${NAMESPACE}/geoFence`,
  async (payload: AddNewRidersToGeoFencePayload) => {
    const geoFenceRepo = new GeoFenceRepository();
    const geoFenceService = new GeoFenceService(geoFenceRepo);
    const response = await geoFenceService.addRiderToGeoFence(payload);
    return response;
  }
);

export const addRiderToGeoFenceSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addRiderToGeoFence.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(addRiderToGeoFence.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(addRiderToGeoFence.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = addRiderToGeoFenceSlice.actions;

export default addRiderToGeoFenceSlice.reducer;
