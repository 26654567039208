import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetAllAdminCommissionState } from '../admin_commission_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { AdminCommissionRepository } from '../repositories/admin_commission.repo';
import { AdminCommissionService } from '../services/admin_commission.service';

const NAMESPACE = 'GET_ADMIN_COMMISSION';

const initialState: GetAllAdminCommissionState = {
  status: HttpStatus.IDLE,
  message: undefined,
  commissions: [],
  paginationCursor: undefined,
};

export const getAdminCommission = createAsyncThunk(`${NAMESPACE}/commission`, async (arg?: PaginationPayload) => {
  const adminCommissionRepo = new AdminCommissionRepository();
  const adminCommissionService = new AdminCommissionService(adminCommissionRepo);
  const response = await adminCommissionService.getAdminCommission(arg);
  return response;
});

const getAdminCommissionSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminCommission.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getAdminCommission.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.commissions = payload.commissions;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getAdminCommission.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getAdminCommissionSlice.reducer;
