import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../utils';

const NAMESPACE = 'ANALYTICS)PAGE';

const initialState = {
  page: PAGES.weekly,
};

export const dashboardSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePage } = dashboardSlice.actions;

export default dashboardSlice.reducer;
