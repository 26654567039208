import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetVendorAdminCommissionState } from '../admin_commission_types';
import { AdminCommissionRepository } from '../repositories/admin_commission.repo';
import { AdminCommissionService } from '../services/admin_commission.service';

const NAMESPACE = 'GET_VENDOR_ADMIN_COMMISSION';

const initialState: GetVendorAdminCommissionState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalVendorCommission: 0, restaurantCommission: 0, storeCommission: 0, pharmacyCommission: 0 },
};

export const getVendorAdminCommission = createAsyncThunk(`${NAMESPACE}/commission`, async () => {
  const adminCommissionRepo = new AdminCommissionRepository();
  const adminCommissionService = new AdminCommissionService(adminCommissionRepo);
  const response = await adminCommissionService.getVendorAdminCommission();
  return response;
});

const getVendorAdminCommissionSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVendorAdminCommission.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getVendorAdminCommission.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getVendorAdminCommission.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getVendorAdminCommissionSlice.reducer;
