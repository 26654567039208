import { PaginationPayload } from '../../../commons/types/pagination.type';
import { SubAdminRepository } from '../repositories/sub_admin.repo';
import {
  GetFilteredStaffsPayload,
  IAddStaffPayload,
  IAddStaffResponsePayload,
  IGetAllStaffsResponsePayload,
  IGetTotalNumberOfStaffs,
} from '../sub_admin_types';

export class SubAdminService {
  constructor(private readonly subAdminRepo: SubAdminRepository) {}

  async getAllStaffs(arg?: PaginationPayload): Promise<IGetAllStaffsResponsePayload> {
    const getAllStaffsResponse = await this.subAdminRepo.getAllStaffs(arg);
    return getAllStaffsResponse;
  }

  async onBoardStaff(payload: IAddStaffPayload): Promise<IAddStaffResponsePayload> {
    const onBoardStaffResponse = await this.subAdminRepo.onBoardStaff(payload);
    return onBoardStaffResponse;
  }

  async totalStaffs(): Promise<IGetTotalNumberOfStaffs> {
    const totalStaffsResponse = await this.subAdminRepo.totalStaffs();
    return totalStaffsResponse;
  }

  async getFilteredStaff(payload: GetFilteredStaffsPayload): Promise<IGetAllStaffsResponsePayload> {
    const getFilteredStaffResponse = await this.subAdminRepo.getFilteredStaff(payload);
    return getFilteredStaffResponse;
  }
}
