import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IDisbursePaymentState, IDisbursePaymentPayload } from '../accounting_types';
import { AccountingRepository } from '../repositories/accounting.repo';
import { AccountingService } from '../services/accounting.service';

const NAMESPACE = 'DISBURSE_PAYMENT';

const initialState: IDisbursePaymentState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const disbursePayment = createAsyncThunk(`${NAMESPACE}/payment`, async (payload: IDisbursePaymentPayload) => {
  const accountingRepo = new AccountingRepository();
  const accountingService = new AccountingService(accountingRepo);
  const response = await accountingService.disbursePayment(payload);
  return response;
});

const disbursePaymentSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(disbursePayment.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(disbursePayment.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(disbursePayment.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = disbursePaymentSlice.actions;

export default disbursePaymentSlice.reducer;
