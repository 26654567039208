import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus, setCredentials } from '../../../commons';
import { IVerifyOTPState } from '../auth_types';
import { AuthenticationRepository } from '../repositories/authentication.repo';
import { AuthenticationService } from '../services/authentication.service';

const NAMESPACE = 'OTP_VERIFICATION';

const initialState: IVerifyOTPState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const verifyUserOTP = createAsyncThunk(`${NAMESPACE}/otp`, async (otp: string) => {
  const authenticationRepo = new AuthenticationRepository();
  const authenticationService = new AuthenticationService(authenticationRepo);
  const response = await authenticationService.verifyUserOTP(otp);
  return response;
});

const OneTimePasswordSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetVerifyOtpStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyUserOTP.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(verifyUserOTP.fulfilled, (state, { payload }) => {
      setCredentials(payload.token, payload.adminId);
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(verifyUserOTP.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error.message ?? String(error);
    });
  },
});

export const { resetVerifyOtpStatus } = OneTimePasswordSlice.actions;

export default OneTimePasswordSlice.reducer;
