import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { CreateAdminCommissionState, AdminCommissionPayload } from '../admin_commission_types';
import { AdminCommissionRepository } from '../repositories/admin_commission.repo';
import { AdminCommissionService } from '../services/admin_commission.service';
import { AddEntityCommission } from '../schema/add_entity_commission.schema';

const NAMESPACE = 'CREATE_ADMIN_COMMISSION';

const initialState: CreateAdminCommissionState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const createAdminCommission = createAsyncThunk(
  `${NAMESPACE}/commission`,
  async (payload: AdminCommissionPayload) => {
    const adminCommissionRepo = new AdminCommissionRepository();
    const adminCommissionService = new AdminCommissionService(adminCommissionRepo);
    const response = await adminCommissionService.createAdminCommission(payload);
    return response;
  }
);

export const addPrivateCommission = createAsyncThunk(
  `${NAMESPACE}/add-private`,
  async (payload: AddEntityCommission) => {
    const adminCommissionRepo = new AdminCommissionRepository();
    const adminCommissionService = new AdminCommissionService(adminCommissionRepo);
    const response = await adminCommissionService.addPrivateCommission(payload);
    return response;
  }
);

export const createAdminCommissionSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAdminCommission.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(createAdminCommission.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(createAdminCommission.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(addPrivateCommission.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(addPrivateCommission.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(addPrivateCommission.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = createAdminCommissionSlice.actions;

export default createAdminCommissionSlice.reducer;
