import { PaginationPayload } from '../../../commons/types/pagination.type';
import {
  AddNewRidersToGeoFencePayload,
  AddNewRidersToGeoFenceResponsePayload,
  DeleteGeoFenceResponsePayload,
  ICreateGeoFencePayload,
  ICreateGeoFenceResponsePayload,
  IGetGeoFenceDetailsPayload,
  IGetGeoFenceDetailsResponsePayload,
  IGetGeoFenceResponsePayload,
} from '../geo_fence_types';
import { GeoFenceRepository } from '../repositories/geo_fence.repo';

export class GeoFenceService {
  constructor(private readonly geoFenceRepo: GeoFenceRepository) {}

  async addRiderToGeoFence(payload: AddNewRidersToGeoFencePayload): Promise<AddNewRidersToGeoFenceResponsePayload> {
    const addRiderToGeoFenceResponse = await this.geoFenceRepo.addRiderToGeoFence(payload);
    return addRiderToGeoFenceResponse;
  }

  async createGeoFence(payload: ICreateGeoFencePayload): Promise<ICreateGeoFenceResponsePayload> {
    const createGeoFenceResponse = await this.geoFenceRepo.createGeoFence(payload);
    return createGeoFenceResponse;
  }

  async deleteGeoFence(payload: IGetGeoFenceDetailsPayload): Promise<DeleteGeoFenceResponsePayload> {
    const deleteGeoFenceResponse = await this.geoFenceRepo.deleteGeoFence(payload);
    return deleteGeoFenceResponse;
  }

  async getGeoFenceDetails(payload: IGetGeoFenceDetailsPayload): Promise<IGetGeoFenceDetailsResponsePayload> {
    const getGeoFenceDetailsResponse = await this.geoFenceRepo.getGeoFenceDetails(payload);
    return getGeoFenceDetailsResponse;
  }

  async getGeoFence(arg?: PaginationPayload): Promise<IGetGeoFenceResponsePayload> {
    const getGeoFenceResponse = await this.geoFenceRepo.getGeoFence(arg);
    return getGeoFenceResponse;
  }
}
