import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  AddNewRidersToGeoFencePayload,
  AddNewRidersToGeoFenceResponsePayload,
  DeleteGeoFenceResponsePayload,
  ICreateGeoFencePayload,
  ICreateGeoFenceResponsePayload,
  IGetGeoFenceDetailsPayload,
  IGetGeoFenceDetailsResponsePayload,
  IGetGeoFenceResponsePayload,
} from '../geo_fence_types';

export class GeoFenceRepository {
  async addRiderToGeoFence(payload: AddNewRidersToGeoFencePayload): Promise<AddNewRidersToGeoFenceResponsePayload> {
    const response = await Api.put<AddNewRidersToGeoFenceResponsePayload, AddNewRidersToGeoFenceResponsePayload>(
      'v1/geo_fence/add-rider',
      payload
    );
    return response;
  }

  async createGeoFence(payload: ICreateGeoFencePayload): Promise<ICreateGeoFenceResponsePayload> {
    const response = await Api.post<ICreateGeoFenceResponsePayload, ICreateGeoFenceResponsePayload>(
      'v1/geo_fence/create',
      payload
    );
    return response;
  }

  async deleteGeoFence(payload: IGetGeoFenceDetailsPayload): Promise<DeleteGeoFenceResponsePayload> {
    const response = await Api.delete<DeleteGeoFenceResponsePayload, DeleteGeoFenceResponsePayload>(
      'v1/geo_fence/delete-geofence',
      { params: payload }
    );
    return response;
  }

  async getGeoFenceDetails(payload: IGetGeoFenceDetailsPayload): Promise<IGetGeoFenceDetailsResponsePayload> {
    const response = await Api.get<IGetGeoFenceDetailsResponsePayload, IGetGeoFenceDetailsResponsePayload>(
      'v1/geo_fence/geo-fence-detail',
      { params: payload }
    );
    return response;
  }

  async getGeoFence(arg?: PaginationPayload): Promise<IGetGeoFenceResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetGeoFenceResponsePayload, IGetGeoFenceResponsePayload>(
      '/v1/geo_fence/get_geo_fence',
      {
        params,
      }
    );
    return response;
  }
}
