import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api } from '../../../utils';
import { HttpStatus } from '../../../commons';
import {
  GetLocalGovernmentInitialState,
  GetLocalGovernmentPayload,
  GetLocalGovernmentResponseObject,
} from './state_types';

const NAMESPACE = 'STATE_LOCAL_GOVERNMENT';

const initialState: GetLocalGovernmentInitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
  localGovernment: [],
};

export const getLocalGovernment = createAsyncThunk(
  `${NAMESPACE}/localGovernment`,
  async (payload: GetLocalGovernmentPayload) => {
    const response = await Api.get<Array<GetLocalGovernmentResponseObject>, Array<GetLocalGovernmentResponseObject>>(
      'v1/lgas',
      {
        params: payload,
      }
    );
    return response;
  }
);

export const localGovernmentSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocalGovernment.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getLocalGovernment.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.localGovernment = payload;
    });
    builder.addCase(getLocalGovernment.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default localGovernmentSlice.reducer;
