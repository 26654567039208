import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredOrderPayload, IGetTotalPlacedOrdersState } from '../orders_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'GET_ORDER_HISTORY';

const initialState: IGetTotalPlacedOrdersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  orders: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const getCompletedOrders = createAsyncThunk(`${NAMESPACE}/orders`, async (arg?: PaginationPayload) => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const response = await orderService.getCompletedOrders(arg);
  return response.completedOrders;
});

export const getCompletedFilteredOrders = createAsyncThunk(
  `${NAMESPACE}/completed-filtered-orders`,
  async (payload: GetFilteredOrderPayload) => {
    const orderRepo = new OrderRepository();
    const orderService = new OrderService(orderRepo);
    const order = await orderService.getFilteredOrders(payload);
    return order;
  }
);

const getCompletedOrdersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompletedOrders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getCompletedOrders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.orders = payload.orders;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getCompletedOrders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getCompletedFilteredOrders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getCompletedFilteredOrders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.orders = payload.orders;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getCompletedFilteredOrders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getCompletedOrdersSlice.reducer;
