import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ICreateCouponState, ICreateCouponPayload } from '../coupon_types';
import { CouponRepository } from '../repositories/coupon.repo';
import { CouponService } from '../services/coupon.service';

const NAMESPACE = 'CREATE_COUPON';

const initialState: ICreateCouponState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const createCoupon = createAsyncThunk(`${NAMESPACE}/coupon`, async (payload: ICreateCouponPayload) => {
  const couponRepo = new CouponRepository();
  const couponService = new CouponService(couponRepo);
  const response = await couponService.createCoupon(payload);
  return response;
});

export const createCouponSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetCreateCouponStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCoupon.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(createCoupon.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(createCoupon.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetCreateCouponStatus } = createCouponSlice.actions;

export default createCouponSlice.reducer;
