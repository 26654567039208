import { SettingRepository } from '../repositories/setting.repo';
import {
  IGetAdminDetailsResponsePayload,
  IGetAdminPaymentSetting,
  IGetNotificationPayload,
  IGetNotificationResponsePayload,
  IUpdateNotificationResponsePayload,
} from '../setting_types';

export class SettingService {
  constructor(private readonly settingRepo: SettingRepository) {}

  async getAdminPaymentSetting(): Promise<IGetAdminPaymentSetting> {
    const getAdminPaymentSettingResponse = await this.settingRepo.getAdminPaymentSetting();
    return getAdminPaymentSettingResponse;
  }

  async getNotification(): Promise<IGetNotificationResponsePayload> {
    const getNotificationResponse = await this.settingRepo.getNotification();
    return getNotificationResponse;
  }

  async updateNotification(payload: IGetNotificationPayload): Promise<IUpdateNotificationResponsePayload> {
    const updateNotificationResponse = await this.settingRepo.updateNotification(payload);
    return updateNotificationResponse;
  }

  async getUserDetails(): Promise<IGetAdminDetailsResponsePayload> {
    const getUserDetailsResponse = await this.settingRepo.getUserDetails();
    return getUserDetailsResponse;
  }
}
