import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetFilteredOrderPayload, IGetActiveOrdersState } from '../orders_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'GET_TOTAL_ACTIVE_ORDERS';

const initialState: IGetActiveOrdersState = {
  status: HttpStatus.IDLE,
  message: undefined,
  orders: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const getTotalActiveOrders = createAsyncThunk(`${NAMESPACE}/get`, async (arg?: PaginationPayload) => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const order = await orderService.getTotalActiveOrders(arg);
  return order;
});

export const getActiveFilteredOrders = createAsyncThunk(
  `${NAMESPACE}/active-filtered-orders`,
  async (payload: GetFilteredOrderPayload) => {
    const orderRepo = new OrderRepository();
    const orderService = new OrderService(orderRepo);
    const order = await orderService.getFilteredOrders(payload);
    return order;
  }
);

const getTotalActiveOrdersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTotalActiveOrders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getTotalActiveOrders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.orders = payload.orders;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getTotalActiveOrders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(getActiveFilteredOrders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getActiveFilteredOrders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.orders = payload.orders;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getActiveFilteredOrders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getTotalActiveOrdersSlice.reducer;
