import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../utils';

const NAMESPACE = 'VENDOR_SCREEN';

const initialState = {
  page: PAGES.request,
};

export const VendorPageSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePage } = VendorPageSlice.actions;

export default VendorPageSlice.reducer;
