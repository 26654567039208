import { Api } from '../../../utils';
import {
  IGetAdminDetailsResponsePayload,
  IGetAdminPaymentSetting,
  IGetNotificationPayload,
  IGetNotificationResponsePayload,
  IUpdateNotificationResponsePayload,
} from '../setting_types';

export class SettingRepository {
  async getAdminPaymentSetting(): Promise<IGetAdminPaymentSetting> {
    const response = await Api.get<IGetAdminPaymentSetting, IGetAdminPaymentSetting>(
      'v1/setting/get-admin-payment-setting'
    );
    return response;
  }

  async getNotification(): Promise<IGetNotificationResponsePayload> {
    const response = await Api.get<IGetNotificationResponsePayload, IGetNotificationResponsePayload>(
      'v1/admin/notification'
    );
    return response;
  }

  async updateNotification(payload: IGetNotificationPayload): Promise<IUpdateNotificationResponsePayload> {
    const response = await Api.put<IUpdateNotificationResponsePayload, IUpdateNotificationResponsePayload>(
      'v1/admin/setting/update',
      payload
    );
    return response;
  }

  async getUserDetails(): Promise<IGetAdminDetailsResponsePayload> {
    const response = await Api.get<IGetAdminDetailsResponsePayload, IGetAdminDetailsResponsePayload>(
      'v1/admin/setting/details'
    );
    return response;
  }
}
