import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  GetFilteredRiderPayload,
  IBlockRiderPayload,
  IBlockRiderResponsePayload,
  IConfirmRiderPayload,
  IConfirmRiderResponsePayload,
  IDeclineRiderPayload,
  IDeclineRiderResponsePayload,
  IDeleteRiderResponsePayload,
  IGetActiveRidersResponsePayload,
  IGetAllActiveRidersResponsePayload,
  IGetAllPendingRidersResponsePayload,
  IGetAllPendingRidersResponsePayloadArray,
  IGetRiderDetailsPayload,
  IGetRiderDetailsResponsePayload,
  IGetRidersByLocationPayload,
  IGetTotalBlockedRidersResponsePayload,
  IGetTotalRidersResponsePayload,
  IGetTotalRidersWithDatePayload,
  TGetRidersByLocationResponsePayload,
} from '../rider_types';

export class RiderRepository {
  async getActiveRiders(arg?: PaginationPayload): Promise<IGetAllActiveRidersResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetAllActiveRidersResponsePayload, IGetAllActiveRidersResponsePayload>(
      '/v1/riders/active',
      {
        params,
      }
    );
    return response;
  }

  async blockRider(payload: IBlockRiderPayload): Promise<IBlockRiderResponsePayload> {
    const response = await Api.put<IBlockRiderResponsePayload, IBlockRiderResponsePayload>(
      '/v2/rider/block-rider',
      payload
    );
    return response;
  }

  async unblockRider(riderId: string): Promise<IBlockRiderResponsePayload> {
    const response = await Api.put<IBlockRiderResponsePayload, IBlockRiderResponsePayload>('/v2/rider/unblock-rider', {
      riderId,
    });
    return response;
  }

  async getBlockedRiders(arg?: PaginationPayload): Promise<IGetAllPendingRidersResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetAllPendingRidersResponsePayload, IGetAllPendingRidersResponsePayload>(
      '/v1/rider/blocked-riders',
      {
        params,
      }
    );
    return response;
  }

  async confirmRider(payload: IConfirmRiderPayload): Promise<IConfirmRiderResponsePayload> {
    const response = await Api.put<IConfirmRiderResponsePayload, IConfirmRiderResponsePayload>(
      'v1/rider/confirm-rider',
      payload
    );
    return response;
  }

  async declineRider(payload: IDeclineRiderPayload): Promise<IDeclineRiderResponsePayload> {
    const response = await Api.put<IDeclineRiderResponsePayload, IDeclineRiderResponsePayload>(
      'v1/rider/decline-rider',
      payload
    );
    return response;
  }

  async getRiderByLocation(payload: IGetRidersByLocationPayload): Promise<TGetRidersByLocationResponsePayload> {
    const response = await Api.get<TGetRidersByLocationResponsePayload, TGetRidersByLocationResponsePayload>(
      'v1/riders/get-by-location',
      { params: payload }
    );
    return response;
  }

  async getRiderDetails(payload: IGetRiderDetailsPayload): Promise<IGetRiderDetailsResponsePayload> {
    const response = await Api.get<IGetRiderDetailsResponsePayload, IGetRiderDetailsResponsePayload>(
      'v1/rider/view-details',
      { params: payload }
    );
    return response;
  }

  async getPendingRiders(arg?: PaginationPayload): Promise<IGetAllPendingRidersResponsePayloadArray> {
    const params = paginationFormatter(arg);
    const response = await Api.get<IGetAllPendingRidersResponsePayloadArray, IGetAllPendingRidersResponsePayloadArray>(
      '/v1/riders/pending',
      {
        params,
      }
    );
    return response;
  }

  async getTotalNumberOfBlockedRiders(): Promise<IGetTotalBlockedRidersResponsePayload> {
    const response = await Api.get<IGetTotalBlockedRidersResponsePayload, IGetTotalBlockedRidersResponsePayload>(
      '/v1/rider/total-blocked'
    );
    return response;
  }

  async getTotalConfirmedRiders(): Promise<IGetActiveRidersResponsePayload> {
    const response = await Api.get<IGetActiveRidersResponsePayload, IGetActiveRidersResponsePayload>(
      '/v1/rider/total-confirmed-riders'
    );
    return response;
  }

  async getTotalRiders(): Promise<IGetActiveRidersResponsePayload> {
    const response = await Api.get<IGetActiveRidersResponsePayload, IGetActiveRidersResponsePayload>(
      '/v1/rider/total-riders'
    );
    return response;
  }

  async geTotalRidersWithinDateRange(payload: IGetTotalRidersWithDatePayload): Promise<IGetTotalRidersResponsePayload> {
    const response = await Api.get<IGetTotalRidersResponsePayload, IGetTotalRidersResponsePayload>(
      'v1/rider/total-riders-with-date',
      { params: payload }
    );
    return response;
  }

  async deleteRider(payload: IGetRiderDetailsPayload): Promise<IDeleteRiderResponsePayload> {
    const response = await Api.delete<IDeleteRiderResponsePayload, IDeleteRiderResponsePayload>(
      'v1/rider/delete_rider',
      { params: payload }
    );
    return response;
  }

  async getFilterRiders(payload?: GetFilteredRiderPayload): Promise<IGetAllActiveRidersResponsePayload> {
    const response = await Api.get<IGetAllActiveRidersResponsePayload, IGetAllActiveRidersResponsePayload>(
      '/v1/filter/rider-filter',
      {
        params: payload,
      }
    );
    return response;
  }
}
